import common from "@/configs/common"
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';
import { isEnvProd } from "@/helpers/environment"
import { getCurrentRoutePath } from "@/helpers/common/common_util"
import storeAuth from "@/helpers/store/storeAuthHelper"

// エラーログ
export function pageErroLog(func, msg) {
  _outputLog(common.datadog_level.error, getCurrentRoutePath(), func, msg);
}
export function errorLog(cls, func, msg) {
  _outputLog(common.datadog_level.error, cls, func, msg);
}

// 情報ログ
export function infoLog(cls, func, msg) {
  if (isEnvProd()) {
    // 本番環境でログ出力指定のある場合のみ出力
    // _outputLog(common.datadog_level.debug, cls, func, msg);
  } else {
    console.log("> " + cls + ":" + func, msg)
  }
}

// デバッグログ
export function debugLog(msg, ...args) {
  if (!isEnvProd()) {
    console.log(msg, ...args)
  }
}

function _outputLog(level, cls, func, msg) {
  let dateTime = new Date();
  const yyyy = dateTime.getFullYear();
  const MM = ("0" + (dateTime.getMonth() + 1)).slice(-2);
  const dd = ("0" + dateTime.getDate()).slice(-2);
  const hh = ("0" + dateTime.getHours()).slice(-2);
  const mm = ("0" + dateTime.getMinutes()).slice(-2);
  const ss = ("0" + dateTime.getSeconds()).slice(-2);
  const sss = ("0" + dateTime.getMilliseconds()).slice(-3);
  const timestamp = yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm + ":" + ss + "." + sss + "+09:00";
  let data = {
    timestamp: timestamp,
    level: level,
    account_id: storeAuth.storeGetAccountId(),
    staff_id: storeAuth.storeGetKeyAsSelectedStaff(),
    company_id: storeAuth.storeGetKeyAsSelectedCompany(),
    brand_id: storeAuth.storeGetKeyAsSelectedBrand(),
    logger_class: cls,
    logger_func: func,
  }
  if (typeof msg == "object" && msg.message != undefined) {
    data.message = msg.message
    data.stack = msg.stack
  } else {
    data.message = msg
  }
  // Datadogエラーログ出力
  if (isEnvProd()) {
    if (common.datadog_level.error == level) {
      datadogLogs.logger.error(cls, data)
    } else {
      datadogLogs.logger.info(cls, data)
    }
  } else {
    // 開発環境はコンソールに出力したエラーログを取得してもらう
    if (common.datadog_level.error == level) {
      console.error("!!! " + cls + ":" + func, data)
    } else {
      console.log("> " + cls + ":" + func, data)
    }
  }
}
export function setDDRumContextProperty(key, property) {
  if (datadogRum.getInitConfiguration()) {
    datadogRum.setGlobalContextProperty(key, property)
  }

}
export function setDDRumUser(associate_id) {
  if (datadogRum.getInitConfiguration()) {
    datadogRum.setUser({
      id: associate_id,
    });
  }
}

export default {
  pageErroLog,
  errorLog,
  infoLog,
  debugLog,
  setDDRumContextProperty,
  setDDRumUser,
}