import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        const conditions = { page: page }
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    getListAll(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/all/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 詳細取得
    getDetail(apiconf, staff_id, checksheet_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/detail",
            query: { staff_id: staff_id, checksheet_id: checksheet_id },
        })
    },

    // 作成
    create(apiconf, staff_id, checksheet, details) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/create",
            query: { staff_id: staff_id, checksheet: checksheet, details: details },
        })
    },

    // 更新
    update(apiconf, staff_id, checksheet, details) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/update",
            query: { staff_id: staff_id, checksheet: checksheet, details: details },
        })
    },

    // 適用ブランド
    addBrand(apiconf, staff_id, checksheet_id, brand_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/add/brand",
            query: { staff_id: staff_id, checksheet_id: checksheet_id, brand_id: brand_id },
        })
    },
    removeBrand(apiconf, staff_id, checksheet_id, brand_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/remove/brand",
            query: { staff_id: staff_id, checksheet_id: checksheet_id, brand_id: brand_id },
        })
    },

    // タグ
    getTagList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/tags/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    createTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/create/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    addTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/add/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    removeTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/remove/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },

    // 履歴取得
    getLogs(apiconf, staff_id, checksheet_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/logs",
            query: { staff_id: staff_id, checksheet_id: checksheet_id },
        })
    },

    // 【My店舗】
    getListMyShop(apiconf, staff_id, shop_id, page) {
        const conditions = { page: page }
        const query = { staff_id: staff_id, conditions: conditions }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/checksheet/list",
            query: query,
        })
    },
    createMyShop(apiconf, staff_id, checksheet, details) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/checksheet/create",
            query: { staff_id: staff_id, checksheet: checksheet, details: details },
        })
    },
}
