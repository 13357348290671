import api_common from "@/helpers/api_common.js";

export default {
    // 一括アップロード
    bulkUpload(apiconf, staff_id, service_cd, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    service_cd: service_cd,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "master/upload/data",
            formdata: formdata,
        })
    },

    // 一括アップロード用フォーマットダウンロード
    downloadBulkUploadFormat(apiconf, staff_id, format_type) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "download/template/" + format_type,
            query: {
                staff_id: staff_id,
            },
        })
    },
}