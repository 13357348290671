import config from "@/configs/timemanagement"

import common_api from '@/helpers/common/common_api'
import timemng_api from '@/helpers/timemng/timemng_api'
import storeAuth from "@/helpers/store/storeAuthHelper"
import storeSystem from "@/helpers/store/storeSystemHelper"
import { paginationLoad } from '@/helpers/api_common'
import { isUserHeadShop, isShopShareMode, isEnabledShopShareMode } from '@/helpers/auth/auth_util'
import {
  dataSort, copyJson, isDisplayModeSP, isSystemApp, isSystemAndroidApp, formatDateStr,
  getCurrentRoutePath, getRoutePath, routerPush, getSystemType, getArrayJoinColumn,
  getFranchiseInfo, cryptoAES, isVManageUsingShop, startExecute,
} from '@/helpers/common/common_util'
import { isNewerNotifications } from "@/helpers/common/notifications"
import { getEnabledRoutes } from "@/helpers/common/route";
import { isEnabledRoleCd, isEnabledCompanyMultiShopManage } from "@/helpers/common/role";
import { getCompanySpecsTaskScheduleUiVer } from '@/helpers/auth/spec'
import { getHeaderLinks, getEnabledHeaderLinks } from "@/helpers/service_util"

// 店舗切り替え後ページ
export function getChangeShopBasePage() {
  return "/todaytask"
}

// ヘッダーアイコンリンク
export function getTimemanagementHeaderIconLinks() {
  let enables = []
  // 設定
  for (const route of getEnabledRoutes('header_toolbar')) {
    if (isShopShareMode() && route.path.includes("chat")) {
      continue
    }
    if (route.path.includes("notification")) {
      route.is_newer = isNewerNotifications()
    }
    enables.push(
      route
    )
  }
  // ヘルプセンター
  enables.push({
    icon: "mdi-help-circle",
    linked_path: "/help",
    external_browser_only: true,
    // target: "_help"
  })
  // 選択中表示
  enables.map(v => v.active = v.linked_path == getCurrentRoutePath() ? true : false)
  return enables
}

// スマホフッターリンク
export function getTimemngSpFooterLinks() {
  let links = []
  const pcheads = getEnabledHeaderLinks()
  if (isUserHeadShop()) {
    // 本部
    const home = pcheads.find(v => v.key == "headshop_home")
    if (home) {
      home.icon = "mdi-home"
      links.push(home)
    }
    const personal_task = pcheads.find(v => v.key == "personal_task")
    if (personal_task) {
      personal_task.icon = "mdi-account-check"
      links.push(personal_task)
    }
    // const shops = pcheads.find(v => v.key == "shops")
    // if (shops) {
    //   shops.icon = "mdi-store-outline"
    //   links.push(shops)
    // }
    const manage = pcheads.find(v => v.key == "timemanagement")
    if (manage) {
      manage.icon = "mdi-wrench-cog"
      manage.linked_path = "/manage"
      links.push(manage)
    }
  } else {
    // 多店舗管理
    if (isEnabledCompanyMultiShopManage() && !isShopShareMode()) {
      const dashboard = pcheads.find(v => v.key == "areamanage")
      if (dashboard) {
        dashboard.icon = "mdi-gauge"
        links.push(dashboard)
      }
    }
    const home = pcheads.find(v => v.key == "todaytask")
    if (home) {
      home.icon = "mdi-check-circle"
      links.push(home)
    }
    const personal_task = pcheads.find(v => v.key == "personal_task")
    if (personal_task) {
      personal_task.icon = "mdi-account-check"
      links.push(personal_task)
    }
    // const myshop = pcheads.find(v => v.key == "myshop_home")
    // if (myshop) {
    //   myshop.icon = "mdi-home"
    //   links.push(myshop)
    // }
  }
  const chat = getEnabledRoutes("footer_toolbar_chat")
  if (chat && chat.length > 0) {
    links.push(...chat)
  }
  links.push({
    icon: "mdi-dots-horizontal",
    linked_path: "/menus",
    name: "メニュー",
    is_newer: isNewerNotifications(),
  })
  links.map(v => {
    v.linked_path = v.linked_path ?? v.to;
    v.active = v.linked_path == getRoutePath() || v.linked_path == getRoutePath() + "/";
  })
  return links
}


// タイムマネジメント左メニュー
export function getTimemanagementLeftMenus(selected) {
  let links = []
  const manage = copyJson(getHeaderLinks().timemanagement)
  if (!isEnabledRoleCd(manage.role_cds)) {
    return links
  }
  manage.sublinks.forEach(link => {
    if (link.role_cds && !isEnabledRoleCd(link.role_cds)) {
      return
    }
    if (link.thirdlinks) {
      let thirds = []
      link.thirdlinks[getCompanySpecsTaskScheduleUiVer()].forEach(third => {
        if (third.role_cds && !isEnabledRoleCd(third.role_cds)) {
          return
        }
        thirds.push({
          key: third.to.substr(1),
          to: third.to,
          text: third.name,
          selected: third.to.includes(selected)
        })
      })
      if (thirds.length > 0) {
        links.push({
          text: link.name,
          sublinks: thirds
        })
      }
    } else {
      links.push({
        key: link.to.substr(1),
        to: link.to,
        text: link.name,
        selected: link.to.includes(selected)
      })
    }
  })
  return links
}

// チェックシートメニュー読み込み
export async function reloadTimemngMenuChecksheets(selected_head) {
  if (selected_head || isDisplayModeSP()) {
    storeAuth.storeSetMenuChecksheets([])
  } else {
    // 店舗共有モード時
    let shop_id = null
    if (isShopShareMode()) {
      shop_id = storeAuth.storeGetKeyAsSelectedShop()
    }
    const results = await timemng_api.apiTimemngChecksheetGetListMyShop(shop_id);
    let items = [];
    results.data.forEach((item) => {
      item.checksheet.range_type = new String(item.checksheet.range_type)
      // TODO:ブランド別でレコードが分かれているのでひとまずマージ
      if (item.checksheet.brand_id) {
        let data = items.find(v => v.checksheet.checksheet_id == item.checksheet.checksheet_id)
        if (data) {
          data.brands.push({ brand_id: item.checksheet.brand_id })
        } else {
          item.brands = [{ brand_id: item.checksheet.brand_id }]
          items.push(item)
        }
      } else {
        items.push(item)
      }
    });
    storeAuth.storeSetMenuChecksheets(items)
  }
}

// ポータル遷移
export function redirectPortalSignout() {
  storeAuth.storeSignout();
  let redirect_url = getPortalUrl() + "auth/signout"
  const result = storeAuth.storeGetSignoutResult();
  if (result) {
    redirect_url += "?result=" + result
  }
  storeAuth.storeSetSignoutResult("");
  window.location = redirect_url
}
export function redirectPortal(self, redirect_path) {
  const params = {
    time: new Date().getTime(),
    token: storeAuth.storeGetTokenInfo(),
    sys: getSystemType(),
    associate: storeAuth.storeGetKeyAsSelectedAssociate(),
    staff: storeAuth.storeGetKeyAsSelectedStaff(),
    shop: storeAuth.storeGetKeyAsSelectedShop(),
    company_group: storeAuth.storeGetKeyAsSelectedCompanyGroup(),
    company: storeAuth.storeGetKeyAsSelectedCompany(),
    brand: storeAuth.storeGetKeyAsSelectedBrand(),
    display_mode: storeSystem.storeGetDisplayMode(),
    proxy_signin: storeAuth.storeGetAccount("proxy_signin"),
    proxy_from_staff: storeAuth.storeGetAccount("proxy_from_staff"),
    redirect_path: redirect_path,
  }
  startExecute(self)
  window.location = getPortalUrl() + "receive?p=" + cryptoAES(JSON.stringify(params))
}
export function getPortalUrl() {
  if (window.location.host.includes("localhost")) {
    return "http://localhost:8001/"
  }
  return config.portal_url;
}

// 選択スタッフ別ページ許可
export function isTimemngEnabledStaffType(userinfo, staff_type) {
  let enabled = false
  const enabled_dashboard = isEnabledCompanyMultiShopManage()
  if (staff_type == "headshop" || staff_type == "task_confirmation") {
    enabled = userinfo.is_head_shop
  } else if (staff_type == "areamanage") {
    enabled = (!userinfo.is_head_shop && enabled_dashboard)
  } else if (staff_type == "shopmanage") {
    enabled = (!userinfo.is_head_shop)
  }
  if (!enabled) {
    if (userinfo.is_head_shop) {
      routerPush("/");
    } else if (isVManageUsingShop(false)) {
      routerPush("/todaytask");
    } else {
      routerPush("/myshop/calendar");
    }
  }
  return enabled
}

// 店舗共有モード切り替え
export function changeShopShareMode(self, is_on) {
  if (is_on == true && !isShopShareMode() && isEnabledShopShareMode()) {
    startExecute(self);
    const share_staff = { dummy: 1 }
    storeAuth.storeSetShopShareMode(storeAuth.storeGetKeyAsSelectedStaff(), share_staff)
    window.location = "/"
  } else if (is_on == false && isShopShareMode()) {
    startExecute(self);
    storeAuth.storeSetShopShareMode(storeAuth.storeGetKeyAsSelectedStaff(), null)
    window.location = "/"
  }
}

// タスク基準当日
export function getTimemngTaskTodayDate() {
  const d = new Date()
  // 選択中店舗のブランドで基準時刻の遅いものを取得
  let exchange_time = getTimemngSelectedBrandExchangeTime()
  if (exchange_time && exchange_time != '2400') {
    try {
      d.setHours(d.getHours() - Number(exchange_time.substr(0, 2)));
      d.setMinutes(d.getMinutes() - Number(exchange_time.substr(2, 4)));
    } catch { } // eslint-disable-line
  }
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
}
export function getTimemngTaskTodayDateYmd() {
  return formatDateStr(getTimemngTaskTodayDate(), "YYYY-MM-DD")
}

// タスク基準当日 未来判定
export function isTimemngCheckableTaskDate(targetDate, check_type) {
  const target = new Date(targetDate);
  const now = getTimemngTaskTodayDate();
  const date_y = target.getFullYear();
  const date_m = target.getMonth();
  const date_d = target.getDate();
  const now_y = now.getFullYear();
  const now_m = now.getMonth();
  const now_d = now.getDate();
  const target_time = new Date(date_y, date_m, date_d).getTime()
  const now_time = new Date(now_y, now_m, now_d).getTime()
  if (check_type == "past") {
    // 過去判定
    return target_time < now_time;
  }
  if (check_type == "present") {
    // 当日判定
    return target_time == now_time
  }
  if (check_type == "future") {
    // 未来判定
    return target_time > now_time
  }
  return new Date(date_y, date_m, date_d).getTime() <=
    new Date(now_y, now_m, now_d).getTime();
}
export function isTimemngDatePast(targetDate) {
  return isTimemngCheckableTaskDate(targetDate, "past")
}
export function isTimemngDatePresent(targetDate) {
  return isTimemngCheckableTaskDate(targetDate, "present")
}
export function isTimemngDateFuture(targetDate) {
  return isTimemngCheckableTaskDate(targetDate, "future")
}

// 選択中店舗ブランドの基準変更時刻取得
export function getTimemngSelectedBrandExchangeTime() {
  let exchange_time = null
  const info = storeAuth.storeGetSelectedShopInfo()
  if (info) {
    for (const brand of info.brand) {
      if (brand.exchange_time && brand.exchange_time != "2400") {
        if (!exchange_time || brand.exchange_time > exchange_time)
          exchange_time = brand.exchange_time
      }
    }
  }
  return exchange_time
}

// ポジション一覧取得
export async function readPositionList(grouping = 'brand', is_myshop_brands = false) {
  const brand_ids = [storeAuth.storeGetKeyAsSelectedCompany()]
  if (!is_myshop_brands) {
    const results = await common_api.apiBrandGetList()
    results.data.forEach(v => brand_ids.push(v.brand_id))
  } else {
    const brands = storeAuth.storeGetSelectedAssociate('brands')
    Object.keys(brands).forEach(brand_id => {
      if (brands[brand_id].shops.find(v => v.shop_id == storeAuth.storeGetKeyAsSelectedShop())) {
        brand_ids.push(brand_id)
      }
    })
  }
  let pagination = { data: [], page: 1, limit: 0 }
  const conditions = {
    brand_ids: brand_ids,
    order: {
      col_num: 2,
      descasc: 'asc'
    }
  }
  const results = await paginationLoad(common_api.apiPositionSearch, pagination, conditions)
  const items = []
  if (grouping == 'brand') {
    // ブランド単位でグルーピング
    new Map(results.data.filter(v => v.brand_id != storeAuth.storeGetKeyAsSelectedCompany())
      .map((v) => [v.brand_id, v])).forEach(idx => {
        items.push({
          text: idx.brand_name,
          items: results.data.filter(v => v.brand_id == idx.brand_id)
        })
      })
    items.push({ is_company: true, text: "ブランド指定なし", items: results.data.filter(v => v.brand_id == storeAuth.storeGetKeyAsSelectedCompany()) })
  } else if (grouping == 'position') {
    // ポジション単位でグルーピング
    new Map(results.data.map((v) => [v.brand_position_name, v])).forEach(idx => {
      items.push({
        brand_position_name: idx.brand_position_name,
        items: results.data.filter(v => v.brand_position_name == idx.brand_position_name)
      })
    })
  }
  return items
}
export function getBrandPositionNameText(item) {
  return item.brand_name ?
    item.brand_position_name + " / " + `<span class="brand-position-name-text">${item.brand_name}</span>`
    : item.brand_position_name
}

// API対象日文字列
export function getSpecDateStr(spec_date) {
  if (spec_date && spec_date instanceof Date) {
    return formatDateStr(spec_date, "YYYY-MM-DD")
  }
  return spec_date
}

// 管理者権限保持
export function isHavingAdminManagerRoles() {
  return isEnabledRoleCd(["admin", "manager"])
}

// （本部以外）店舗一覧取得
export async function getRealShopList(contain_myshop = false, include_closed_shop = true, vmanage_shop_only = false, contain_franchisee = true) {
  let conditions = {
    order: {
      col_num: 9,
      descasc: "asc"
    }
  }
  if (include_closed_shop) conditions.include_closed_shop = include_closed_shop == true ? 9 : 1
  if (vmanage_shop_only) conditions.vmanage = vmanage_shop_only == true ? 1 : 0
  const results = await common_api.apiFcShopList(conditions);
  let shops = [];
  results.data.forEach((e) => {
    e.franchise_info = getFranchiseInfo(e)
    if (!contain_myshop) {
      if (!e.franchise_info.is_franchise && !e.franchise_info.is_myshop) return
    }
    if (!contain_franchisee) {
      if (e.franchise_info.is_franchise && !e.franchise_info.is_myshop) return
    }
    // if (vmanage_shop_only) {
    //   if (!isShopEditableVManage(e.vmanage)) return
    // }
    e.area_text = getArrayJoinColumn(e.areas, "area_name", "、");
    e.area_ids = getTargetItem(e.areas, "area_id");
    e.brand_text = getArrayJoinColumn(e.brands, "brand_name", "、");
    e.brand_ids = getTargetItem(e.brands, "brand_id");
    shops.push(e);
  });
  dataSort(shops, ["shop", "shop_name"], true);
  return shops;

  function getTargetItem(items, column) {
    let list = [];
    items.forEach((item) => {
      list.push(item[column]);
    });
    return list;
  }
}

// フランチャイズ
export async function readContractedFranchiserRights() {
  return (await common_api.apiFcGetFranchiserRights()).data.filter(v => v.status == 0);
}
export async function readFranchiseeRights() {
  return (await common_api.apiFcGetFranchiseeRights()).data
}

// タスクビープ音再生
export function playTaskBeep(beep) {
  if (isSystemApp() && !isSystemAndroidApp()) {
    // iOS端末のみ有効
    document.getElementById("audioSource").src = require("@/assets/beep/" + beep);
    document.getElementById("audioPlayback").load();
    document.getElementById("audioPlayback").play();
  }
}

// 業務スケジュール新旧UI判定
export function isNewTaskScheduleUi() {
  return getCompanySpecsTaskScheduleUiVer() == 1
}

// 翻訳
export function translateTaskData(lang, base_value, translate_list, target_column) {
  if (!lang || !translate_list) return base_value
  const data = translate_list[lang]
  if (!data) return base_value
  const translate_value = data[target_column]
  return translate_value && translate_value != '' ? translate_value : base_value
}
export function translateChecksheetData(lang, checksheet_id, checksheets, target_column, base_column = target_column) {
  const checksheet = checksheets.find(v => v.checksheet.checksheet_id == checksheet_id)
  if (!checksheet) return ""
  const base_value = checksheet.checksheet[base_column]
  if (!lang) return base_value
  const data = checksheet.translate[lang]
  if (!data) return base_value
  const translate_value = data[target_column]
  return translate_value && translate_value != '' ? translate_value : base_value
}

export default {
  getChangeShopBasePage,

  // ヘッダーアイコンリンク
  getTimemanagementHeaderIconLinks,

  // スマホフッターリンク
  getTimemngSpFooterLinks,

  // タイムマネジメント左メニュー
  getTimemanagementLeftMenus,

  // チェックシートメニュー読み込み
  reloadTimemngMenuChecksheets,

  // ポータル遷移
  redirectPortalSignout,
  redirectPortal,
  getPortalUrl,

  // 選択スタッフ別ページ許可
  isTimemngEnabledStaffType,

  // タスク基準当日
  getTimemngTaskTodayDate,
  getTimemngTaskTodayDateYmd,

  // タスク基準当日 未来判定
  isTimemngCheckableTaskDate,
  isTimemngDatePast,
  isTimemngDatePresent,
  isTimemngDateFuture,

  // 選択中店舗ブランドの基準変更時刻取得
  getTimemngSelectedBrandExchangeTime,

  // ポジション一覧取得
  readPositionList,
  getBrandPositionNameText,

  // API対象日文字列
  getSpecDateStr,

  // 管理者権限保持
  isHavingAdminManagerRoles,

  // （本部以外）店舗一覧取得
  getRealShopList,

  // フランチャイズ
  readContractedFranchiserRights,
  readFranchiseeRights,

  // タスクビープ音再生
  playTaskBeep,

  // 業務スケジュール新旧UI判定
  isNewTaskScheduleUi,

  // 翻訳
  translateTaskData,
  translateChecksheetData,
}
