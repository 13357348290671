<template>
  <v-card class="contents mx-auto overflow-hidden not-found-error-page">
    <div class="text-center">
      <div>
        <img src="@/assets/infomation-icon.svg" alt="" class="not-found-error-page-img">
      </div>
      <div class="font-weight-bold not-found-error-page-title">ページが見つかりませんでした</div>
      <div class="mt-4">
        数秒で自動的にホームに戻ります。
      </div>
      <div class="mt-6">
        <v-btn @click="clickHome()" color="#1B6DBE" variant="outlined" class="not-found-error-page-return-btn" min-height="46">
          ホームに戻る
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    timer_sec: 3,
    is_move: true,
  }),
  setup() {
    const { waitSleep } = require('@/helpers/common/common_util')
    return {
      waitSleep
    }
  },
  async created() {
    await this.waitSleep(this.timer_sec)
    if (this.is_move) {
      this.$router.push("/");
    }
  },
  methods: {
    clickHome() {
      this.is_move = false;
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.not-found-error-page {
  background-color: #F3F7F9 !important;
  height: 100vh;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-error-page-title {
  font-size: 24px;
  margin-top: 40px;
}

.not-found-error-page-return-btn {
  padding: 12px 16px;
  border-radius: 3px;
  background-color: #fff;
}

.not-found-error-page-img {
  min-width: 88px;
  max-width: 88px;
}
</style>