import api_common from "@/helpers/api_common.js";

export default {

    // 一覧取得
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/create",
            query: { staff_id: staff_id, task: task },
        })
    },

    // 更新
    update(apiconf, staff_id, personal_task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/update",
            query: { staff_id: staff_id, task: personal_task },
        })
    },

    // 適用ユーザー変更
    addTarget(apiconf, staff_id, personal_task_id, associate_ids, with_push) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/add/target",
            query: { staff_id: staff_id, personal_task_id: personal_task_id, associate_ids: associate_ids, with_push: with_push },
        })
    },
    removeTarget(apiconf, staff_id, personal_task_id, associate_ids) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/rm/target",
            query: { staff_id: staff_id, personal_task_id: personal_task_id, associate_ids: associate_ids },
        })
    },

    // タグ
    addTag(apiconf, staff_id, personal_task_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/add/tag",
            query: { staff_id: staff_id, personal_task_id: personal_task_id, tags: tags },
        })
    },
    removeTag(apiconf, staff_id, personal_task_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/rm/tag",
            query: { staff_id: staff_id, personal_task_id: personal_task_id, tags: tags },
        })
    },

    // ファイル
    addAttachment(apiconf, staff_id, personal_task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: personal_task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "personal/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachment(apiconf, staff_id, personal_task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/rm/attachment",
            query: { staff_id: staff_id, task: personal_task },
        })
    },
    previewFile(apiconf, staff_id, personal_task_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/personal/task/file",
            query: {
                staff_id: staff_id,
                personal_task_id: personal_task_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    // ファイルキャプション
    saveAttachmentCaption(apiconf, staff_id, personal) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/event/update/caption",
            query: { staff_id: staff_id, personal: personal },
        })
    },
}
