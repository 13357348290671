import api_common from "@/helpers/api_common.js";

// サインイン
export function signin(apiconf, userid, passwd) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "auth/signin",
        query: { userid: userid, passwd: passwd },
        checktoken: false,
        checkpolicy: false,
        checkaccount: true,
    })
}
export function signinExternal(apiconf, account_verify, id_token, access_token) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "auth/signin",
        query: { userid: null, passwd: null, external_idp_result: { account_verify: account_verify, id_token: id_token, access_token: access_token } },
        checktoken: false,
        checkpolicy: false,
        checkerror: false,
    })
}

// サインアウト
export function signout(apiconf, access_token, staff_id) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "auth/signout",
        query: { access_token: access_token, staff_id: staff_id },
        checktoken: false,
        checkpolicy: false,
        checkerror: false,
    })
}

// トークンリフレッシュ
export function tokenRefresh(apiconf, staff_id, refresh_token, idpflg) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "token/refresh",
        query: { staff_id: staff_id, token: refresh_token, idpflg: idpflg },
        checktoken: false,
        checkpolicy: false,
    })
}

export default {
    signin,
    signinExternal,
    signout,
    tokenRefresh,
}