import config from '@/configs/timemanagement';

export default
    [
        {
            path: config.basepath + '/dashboard',
            name: 'timemanagement-dashboard',
            component: () => import('@/components/timemanagement/dashboard/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },
        {
            path: config.basepath + '/dashboard/:mode',
            name: 'timemanagement-dashboard-mode',
            component: () => import('@/components/timemanagement/dashboard/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },
        {
            path: config.basepath + '/dashboard/:mode/:date',
            name: 'timemanagement-dashboard-date',
            component: () => import('@/components/timemanagement/dashboard/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },
    ]
