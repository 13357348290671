import RouteChat from './chat'
import RouteNotifications from './notifications'
import RouteReceive from './receive'
import RouteError from './error'

export default
    [
        ...RouteChat,
        ...RouteNotifications,
        ...RouteReceive,
        ...RouteError,
    ]