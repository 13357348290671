// Utilities
import { defineStore } from 'pinia'

export const useStoreAuth = defineStore('eatery-auth', {
  persist: true,
  state: () => ({
    prev_signin_type: null,
    signin_info: {},
    signup_info: null,
    token: {},
    token_sync: null,

    id_token: null,
    access_token: null,
    refresh_token: null,
    token_refresh_required: false,
    external_username: null,
    user_sync: null,

    reload_redirect: null,

    activate_status: null,
    profile_error: null,
    account: null,
    associates: null,
    staff_group: null,
    staff_share_user: {},
    staff_roles: {},
    app_settings: null,
    account_selected_shop: {
      // associate: null,
      // staff: null,
      // shop: null,
    },
    roles: null,
    custom_claims: null,

    shop_share: {},

    shop_profile: {},
    selected_shop_id: null,
    signin_shop_cd: null,

    system_mode: null,
    design_mode: null,
    signout_result: null,
    site_redirect_param: null,

    account_specs: [],
    company_specs: [],

    notification_stats: [],

    unread_message_last_reload: null,
    unread_message_flag: null,
    unread_message_count: null,

    timemng_menu_checksheets: [],
  }),
})
