import api_common from "@/helpers/api_common.js";

export default {

    // 情報取得
    getInfo(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/info",
            query: { staff_id: staff_id },
        })
    },

    // 一覧取得
    getList(apiconf, account_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/list",
            query: { account_id: account_id, page: page },
        })
    },

    // 更新
    update(apiconf, staff_id, company) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/update",
            query: { staff_id: staff_id, company: company },
        })
    },

    // 企業コード生成
    newCode(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "random/companycd/create",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}