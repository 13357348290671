export default
    [
        {
            path: '/auth/signout',
            name: 'auth_signout',
            component: () => import('@/components/auth/pages/SignOut'),
        }, {
            path: '/auth/savetoken',
            name: 'auth_savetoken',
            component: () => import('@/components/auth/pages/SaveToken'),
        }, {
            path: '/auth/getuserinfo',
            name: 'auth_getuserinfo',
            component: () => import('@/components/auth/pages/GetUserInfo'),
            meta: { title: "最新の情報を取得" },
        },

        // UT用
        // {
        //     path: '/auth/ut/signin',
        //     name: 'auth_ut_signin',
        //     component: () => import('@/components/auth/UnitTestSignin'),
        // },
    ]
