import RouteManage from './manage'
import RouteShops from './shops'
import RouteHeadShop from './headshop'
import RouteMyShop from './myshop'
import RouteRoutine from './routine'
import RouteDashBoard from './dashboard'
import RouteReport from './report'
import RouteOthers from './others'

export default
    [
        ...RouteManage,
        ...RouteShops,
        ...RouteHeadShop,
        ...RouteMyShop,
        ...RouteRoutine,
        ...RouteDashBoard,
        ...RouteReport,
        ...RouteOthers,
    ]