import api_common from "@/helpers/api_common.js";

export default {

    // お問い合わせ
    save(apiconf, params) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "contact/post",
            query: { data: params },
        })
    },
}