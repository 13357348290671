<template>
  <header class="header">
    <div class="toolbar">
      <div class="toolbar-inner">
        <div class="toolbar-logo">
          <div class="toolbar-logo-img p-relative">
            <img :src="getLogoVManage()" width="110" />
            <button class="basic-table-menu-toggle logo-toggle">
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </button>
            <div class="menu-drawer logo-toggle-menu">
              <div class="menu-drawer-item">
                <button @click="redirectPortal(this)">
                  <img :src="getLogoFPortal()" width="110" />
                </button>
              </div>
              <div class="menu-drawer-item">
                <button>
                  <img :src="getLogoVManage()" width="110" />
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="toolbar-date-time ml-2 font-weight-bold">
            {{ nowdatetime }}
          </div> -->
        </div>
        <div class="toolbar-menu">
          <div class="toolbar-icons">
            <div v-for="(menu, i) in getTimemanagementHeaderIconLinks()" :key="i">
              <button :class="{ active: menu.active }" @click="pageMove(menu)">
                <i :class="'mdi ' + menu.icon"></i>
                <!-- 通知バッヂ -->
                <div v-if="menu.is_newer" class="notification-badge"></div>
                <div v-if="menu.name == 'チャット' && unread_count > 0" class="notification-badge">
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="toolbar-account">
          <div class="toolbar-account-btn" :class="{ shareActive: share_mode }">
            <span class="toolbar-store-name">{{
              share_mode ? viewCompanyOrShopName() : viewCompanyOrShopName()
            }}</span>
            <div v-if="share_mode" class="share-active-avatar">
              <v-icon color="#103087">mdi-account-multiple</v-icon>
            </div>

            <v-avatar v-else-if="url_avatar" size="40">
              <img :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
            </v-avatar>
            <div v-else class="v-avatar">
              <i class="mdi mdi-account"></i>
            </div>
          </div>
          <div class="toolbar-account-detail" v-if="!change_shop_modal">
            <div class="toolbar-switch" v-if="isEnabledShopShareMode()">
              <div class="toolbar-switch-title">
                <v-icon color="#103087">mdi-account-multiple</v-icon>
                店舗共有モード
              </div>
              <div class="toolbar-switch-switch">
                <div class="d-flex align-center">
                  <input type="checkbox" class="switch-checkbox" id="switch1" v-model="share_mode"
                    @change="changeShopShareMode(this, share_mode)" />
                  <div class="switch-text switch-text-off mr-2">OFF</div>
                  <label class="switch-label" for="switch1"></label>
                  <div class="switch-text switch-text-on ml-2">ON</div>
                </div>
              </div>
            </div>
            <div class="toolbar-account-name">
              <div class="toolbar-account-icon">
                <div class="v-avatar" v-if="url_avatar">
                  <v-avatar size="40">
                    <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
                  </v-avatar>
                </div>
                <div class="v-avatar" v-else>
                  <i class="mdi mdi-account"></i>
                </div>
              </div>
              <div class="font-weight-bold mt-2">
                {{ parents.userinfo.staff_name }}
              </div>
            </div>
            <div class="toolbar-account-place">
              <div class="toolbar-account-company">
                {{ parents.userinfo.company_full_name }}<br />
                {{ parents.userinfo.brand_name }}
              </div>
              <div class="toolbar-account-store font-weight-bold">
                {{ parents.userinfo.shop_name }}
              </div>
              <div class="toolbar-account-role">
                {{ getUserRoleName() }}
              </div>
            </div>
            <div class="toolbar-account-change">
              <button @click="viewChangeShop()">
                <i class="mdi mdi-cached"></i>
                店舗切り替え
              </button>
            </div>
            <div class="toolbar-account-reload">
              <button @click="pageMove('/auth/getuserinfo')">
                最新の情報を取得
              </button>
            </div>
            <div class="toolbar-account-logout">
              <button @click="pageMove('/auth/signout')">サインアウト</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChangeShopModal v-if="change_shop_modal" :close="changeShop" />
    <ConfirmModal v-if="confirm_modal" title="編集を中断します。" message="編集を中断します。よろしいですか？" :close="execMove" />
    <ExecutingOverlay ref="execute" />
  </header>
</template>

<script>
import ChangeShopModal from "@/components/common/change_shop/PcChangeShopModal";
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
import ConfirmModal from "@/components/common/ConfirmModal";
import config from "@/configs/common";
export default {
  props: ["parents"],
  components: { ChangeShopModal, ExecutingOverlay, ConfirmModal },
  data: () => ({
    url_avatar: true,

    change_service: false,
    change_shop_modal: false,
    confirm_modal: false,

    share_mode: false,

    unread_count: 0,
    icon_name: 'チャット',
  }),
  setup() {
    const { storeGetUnreadMessageLastReloadTime, storeSetUnreadMessageLastReloadTime,
      storeGetUnreadMessageCount, storeSetUnreadMessageCount } = require("@/helpers/store/storeAuthHelper")
    const { apiChatGetUnreadMessage } = require('@/helpers/common/common_api')
    const { debugLog } = require('@/helpers/common/datadog')
    const { getUserRoleName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode } = require('@/helpers/auth/auth_util')
    const { getTimemanagementHeaderIconLinks, redirectPortal, changeShopShareMode } = require('@/helpers/timemng/timemng_util')
    const { pageMove, getLogoFPortal, openExternalWindow, getLogoVManage } = require('@/helpers/common/common_util')
    return {
      storeGetUnreadMessageLastReloadTime, storeSetUnreadMessageLastReloadTime,
      storeGetUnreadMessageCount, storeSetUnreadMessageCount,
      apiChatGetUnreadMessage,
      debugLog,
      getUserRoleName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode,
      getTimemanagementHeaderIconLinks, redirectPortal, changeShopShareMode,
      pageMove, getLogoFPortal, openExternalWindow, getLogoVManage,
    }
  },
  created() {
    this.share_mode = this.isShopShareMode();
    this.checkAndInitChatRooms();
  },

  methods: {
    // チャット未読情報読み込み
    async checkAndInitChatRooms() {
      if (this.isShopShareMode()) return
      let unread_count = this.storeGetUnreadMessageCount();
      if (unread_count) this.unread_count = unread_count.unread
      const lastReloadString = this.storeGetUnreadMessageLastReloadTime()
      const lastReload = lastReloadString ? new Date(lastReloadString) : null;
      const now = new Date();
      if (!unread_count || !lastReload || Math.abs(now.getTime() - lastReload.getTime()) / 1000 > config.chat_message_reload_sec) {
        try {
          unread_count = await this.apiChatGetUnreadMessage()
          this.storeSetUnreadMessageLastReloadTime();
          this.storeSetUnreadMessageCount(unread_count);
          if (unread_count) this.unread_count = unread_count.unread
        } catch (err) {
          this.debugLog(err);
        }
      }
    },

    viewCompanyOrShopName() {
      return this.parents.userinfo.is_head_shop
        ? this.parents.userinfo.company_name
        : this.parents.userinfo.shop_name;
    },

    checkHeaderLink(link) {
      if (link.external) {
        this.openExternalWindow(link.external);
      } else {
        this.pageMove(link.to);
      }
    },

    viewChangeShop() {
      this.change_shop_modal = true;
    },
    changeShop() {
      this.change_shop_modal = false;
    },

    // 遷移確認
    confirmMove(event) {
      if (this.parents.is_move_confirm) {
        this.save_event = event;
        this.confirm_modal = true;
        return true;
      }
      return false;
    },
    execMove(is) {
      // eslint-disable-line
      if (is) {
        // eslint-disable-line
      }
      this.confirm_modal = false;
    },
  },
  // computed: {
  //   nowdatetime() {
  //     return this.formatDateStr(this.clock, "MM月DD日（W）hh:mm");
  //   },
  // },
  // unmounted() {
  //   if (this.clock_timeout) clearTimeout(this.clock_timeout);
  // },
};
</script>

<style scoped>
.toolbar i {
  font-size: 20px;
}

.toolbar a {
  color: inherit;
}

.toolbar {
  height: 48px;
  background-color: #fff;
}

.toolbar-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
}

.toolbar-logo {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.toolbar-change {
  margin-left: 8px;
  position: relative;
}

.toolbar-change-menu {
  padding-left: 0;
  list-style: none;
  position: absolute;
  top: 41px;
  left: -100px;
  right: -100px;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 0px 24px #00000029;
  border-radius: 0.75rem;
}

.toolbar-change-menu .current {
  position: relative;
}

.toolbar-change-menu .current:before {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Material Design Icons";
  content: "\F012C";
  font-size: 24px;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  color: #1562A4;
}

.toolbar-change-menu li {
  border-top: 1px solid #ddd;
}

.toolbar-change-menu li:first-child {
  border-top: 0;
}

.toolbar-change-menu li a {
  display: block;
  padding: 8px 16px;
}

.toolbar-change-menu img {
  vertical-align: middle;
}

.toolbar-change button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background: #eee;
  padding: 0;
  border: none;
}

.toolbar-change button:hover {
  cursor: pointer;
}

.toolbar-change i {
  font-size: 1.25rem;
  color: #1976D2;
}

.toolbar-change i,
.toolbar-logo-img img {
  vertical-align: middle;
}

.toolbar-menu {
  margin-left: auto;
}

.toolbar-icons {
  display: flex;
  align-items: center;
}

.toolbar-icons button {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border: none;
  border-radius: 4px;
  background: none;
  position: relative;
}

.toolbar-icons button.active {
  background-color: #CF197A;
}

.toolbar-icons button.active i {
  color: #fff;
}

.toolbar-icons>div+div {
  margin-left: 24px;
}

button.notification::before {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: #FFB324;
  top: 6px;
  right: -0.25rem;
  border: 2px solid #fff;
}

.toolbar-icons i {
  color: #666666;
}

.toolbar-account {
  margin-left: 24px;
  position: relative;
}

.toolbar-switch+.toolbar-account-name {
  margin-top: 8px;
}

.toolbar-switch {
  padding: 16px;
  margin: -16px -16px 0;
  background: #F3F0F0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar-switch-title {
  color: #103087;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  width: 28px;
  height: 14px;
  background-color: #ccc;
  border-radius: 26px;
  position: relative;
}

.switch-checkbox:checked~.switch-label {
  background-color: #1B6DBE;
}

.switch-label:before {
  border: 1px solid #ccc;
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  top: 0;
  left: 0;
  border-radius: 26px;
  transition: .2s;
}

.switch-checkbox:checked~.switch-label:before {
  transform: translateX(15px);
  background-color: #FFFFFF;
  border: 1px solid #1B6DBE;
}

.switch-text-off {
  color: #222;
  font-weight: bold;
}

.switch-checkbox:checked~.switch-text-on {
  color: #1B6DBE;
  font-weight: bold;
}

.switch-checkbox:checked~.switch-text-off {
  color: #666;
  font-weight: normal;
}

.switch-checkbox~.switch-text,
.switch-checkbox:checked+.switch-text {
  font-size: 12px;
}

.toolbar-account .share-active-avatar {
  display: inline-block;
}

.share-active-avatar {
  margin-right: -8px;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  border-radius: 50%;
  color: #103087;
}

.toolbar-account-name {
  padding: 16px;
  border-radius: 0.75rem;
}

.toolbar-account p {
  margin: 0;
}

.toolbar-account-btn {
  background: #f8f5f5;
  padding: 4px 12px;
  border-radius: 24px;
}

.toolbar-account-btn.shareActive {
  background: #103087;
  color: #fff;
}

.toolbar-account-btn img {
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.toolbar-account-btn>* {
  display: inline;
  vertical-align: middle;
}

.toolbar-account-icon img {
  width: 100%;
}

.toolbar-store-name {
  margin-right: 8px;
}

.toolbar-account-detail {
  position: absolute;
  top: 100%;
  background-color: #fff;
  padding: 16px 16px 0;
  right: 0;
  width: 310px;
  box-shadow: 0px 0px 24px #00000029;
  border-radius: 0.75rem;
  z-index: 20;
  display: none;
}

.toolbar-account-btn:hover {
  cursor: pointer;
}

.toolbar-account-btn:hover+.toolbar-account-detail,
.toolbar-account-detail:hover {
  display: block;
}

.toolbar-account-place {
  border-radius: 0.75rem;
  padding: 16px;
}

.toolbar-account-place:hover,
.toolbar-account-name:hover {
  background: #f8f5f5;
}

.toolbar-account .toolbar-account-store {
  margin-top: 8px;
  font-weight: bold;
}

.toolbar-account-change {
  margin-top: 8px;
}

.toolbar-account-change i {
  font-size: 1.25rem;
  margin-right: 8px;
}

.toolbar-account-change button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
  border: 1px solid #1B6DBE;
  border-radius: 4px;
  color: #1562A4;
  background-color: #fff;
}

.toolbar-account-change button span {
  vertical-align: middle;
}

.toolbar-account-reload,
.toolbar-account-logout {
  padding: 16px 0;
  border-top: 1px solid #ddd;
  text-align: center;
  color: #1B6DBE;
}

.toolbar-account-reload {
  margin-top: 16px;
}

.toolbar-account-logout a {
  color: #666666;
}

.logo-toggle-menu {
  right: unset;
  left: 110px;
  top: calc(100% - 4px);
  width: 200px;
  padding: 0;
  display: none;
}

.logo-toggle:hover+.logo-toggle-menu,
.logo-toggle-menu:hover {
  display: block;
}

.logo-toggle-menu .menu-drawer-item button {
  padding: 16px;
}

.logo-toggle-menu .menu-drawer-item button:hover {
  background-color: #f1ebeb;
}

.logo-toggle-menu .menu-drawer-item:first-child button:hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.logo-toggle-menu .menu-drawer-item:last-child button:hover {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.logo-toggle-menu .menu-drawer-item+.menu-drawer-item {
  border-top: 1px solid #ccc;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #ffb324;
  border-radius: 50%;
}
</style>