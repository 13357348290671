
import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "role/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 作成
    create(apiconf, staff_id, role) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "role/create",
            query: { staff_id: staff_id, role: role },
        })
    },

    // 更新
    update(apiconf, staff_id, role, policies) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "role/update",
            query: { staff_id: staff_id, role: role, policies: policies },
        })
    },
}