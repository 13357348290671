<template>
  <div>
    <SpPage v-if="isDisplayModeSP()" :parents="parents" />
    <PcPage v-else :parents="parents" />
  </div>
</template>

<script>
import PcPage from "./PcErrorNotFound";
import SpPage from "./SpErrorNotFound";
export default {
  props: { parents: Object },
  components: { PcPage, SpPage },
  setup() {
    const { isDisplayModeSP } = require('@/helpers/common/common_util')
    return {
      isDisplayModeSP
    }
  },
};
</script>
