import api_common from "@/helpers/api_common.js";

export default {

    // ロール追加
    roleAdd(apiconf, staff_id, to_id, role_ids) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staff/event/add/role",
            query: { staff_id: staff_id, to_id: to_id, role_id: role_ids }
        })
    },

    // ロール削除
    roleDelete(apiconf, staff_id, from_id, role_ids) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staff/event/rm/role",
            query: { staff_id: staff_id, from_id: from_id, role_id: role_ids }
        })
    },
}