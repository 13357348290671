import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 新規作成
    create(apiconf, staff_id, area) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/create",
            query: { staff_id: staff_id, area: area },
        })
    },

    // 更新
    update(apiconf, staff_id, area) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/update",
            query: { staff_id: staff_id, area: area },
        })
    },

    // コード生成
    newCode(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "random/areacd/create",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}