<template>
  <div :class="{ sp: isDisplayModeSP() }">
    <router-view :parents="params" />
  </div>
</template>

<script>
export default {
  name: "TimemanagementIndex",
  setup() {
    const { setServiceBaseTitle } = require('@/helpers/common/common_util')
    const { getUserInfo } = require('@/helpers/auth/auth_util')
    const { isDisplayModeSP } = require('@/helpers/common/common_util')
    return { setServiceBaseTitle, getUserInfo, isDisplayModeSP }
  },
  data: () => ({
    params: {
      userinfo: {
        company_name: null,
        shop_name: null,
        staff_name: null,
      },
      role: null,
    },
  }),
  created() {
    let self = this;
    self.setServiceBaseTitle();
    self.params.userinfo = self.getUserInfo();
  },
  mounted() {
    this.setServiceBaseTitle();
  },
  updated() {
    this.setServiceBaseTitle();
  },
};
</script>
