import { generateUUID_V4, waitSleepMsec } from "@/helpers/common/common_util"
import { debugLog  } from "@/helpers/common/datadog"

/* eslint-disable no-undef */
// 共通イベントコール
export function appEventCall(event) {
  callApp.postMessage(event)
}

// 外部ブラウザ表示
export function appBrowserOpen(url) {
  browserOpenApp.postMessage(url)
}

// ファイルダウンロード
export function appFileDownload(file) {
  downloadFileApp.postMessage(file)
}

// （Androidアプリ用）ファイルダウンロード
export async function appAndroidFileDownload(filename, base64data) {
  const size = 60000;
  const spls = Math.ceil(base64data.length / size);
  const key = generateUUID_V4()
  debugLog("length: ", base64data.length)
  for (var i = 0, x = 0; i < spls; ++i, x += size) {
    const idx = new String(i + 1).toString()
    const msg = key + ":::" + filename + ":::" + spls + ":::" + idx + ":::" + base64data.substr(x, size)
    debugLog(key, filename, spls, idx, base64data.substr(x, size).length)
    androidFileDownloadApp.postMessage(msg)
    await waitSleepMsec(50);  // 一応ちょっとだけ間隔開けつつ
  }
}

export default {
  data: () => ({
    voice_callback: null,
    android_download: [],
  }),

  // 共通イベントコール
  appEventCall,
  appBrowserOpen,
  appFileDownload,
  appAndroidFileDownload,
}
/* eslint-enable */