import config from '@/configs/timemanagement';
export default
    [

        {
            path: config.basepath + '/manage',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-manage',
                    component: () => import('@/components/timemanagement/manage/list/IndexManageList'),
                    meta: { title: '業務管理' },
                },
            ]
        },

        {
            path: config.basepath + '/manage/task/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-task',
                    component: () => import('@/components/timemanagement/manage/task/IndexTask'),
                    meta: { title: 'タスク', policy: { function_cd: "task", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false } },
                },
            ]
        },

        {
            path: config.basepath + '/manage/template/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-template',
                    component: () => import('@/components/timemanagement/manage/template/IndexTaskTemplate'),
                    meta: { title: 'タスクグループ', policy: { function_cd: "template", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false, task_schedule_ui_ver: 0 } },
                },
            ]
        },

        {
            path: config.basepath + '/manage/pattern/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-task_schedule',
                    component: () => import('@/components/timemanagement/manage/schedule/IndexSchedule'),
                    meta: { title: '業務スケジュール', policy: { function_cd: "pattern", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false } },
                },
            ]
        },

        {
            path: config.basepath + '/manage/checksheet/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-checksheet',
                    component: () => import('@/components/timemanagement/manage/checksheet/IndexChecksheet'),
                    meta: { title: 'チェックシート', policy: { function_cd: "checksheet", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false } },
                },
            ]
        },

        {
            path: config.basepath + '/manage/emergency',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-emergency',
                    component: () => import('@/components/timemanagement/manage/emergency/IndexEmergency'),
                    meta: { title: '臨時タスク', policy: { function_cd: "emergency", crud: ['c', 'r', 'u', 'd'], is_head: true } },
                },
            ]
        },
        {
            path: config.basepath + '/manage/emergency/regist',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-emergency-regist',
                    component: () => import('@/components/timemanagement/manage/emergency/IndexEmergency'),
                    meta: { title: '臨時タスク', policy: { function_cd: "emergency", crud: ['c', 'r', 'u', 'd'], is_head: true }, initmode: "regist" },
                },
            ]
        },

        {
            path: config.basepath + '/manage/personal_task',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-manage-personal-task',
                    component: () => import('@/components/timemanagement/manage/personal_task/IndexPersonalTask'),
                    meta: { title: '個人タスク', policy: { function_cd: "timemng", crud: ['c', 'r', 'u', 'd'], is_head: true } },
                },
            ]
        },
    ]


