<template>
  <footer class="sp-footer-menus">
    <div v-for="(link, i) in getTimemngSpFooterLinks()" :key="i">
      <button @click="clickLink(link)" :class="{ active: isActive(link) }">
        <div class="sp-footer-icon-wrap">
          <v-icon :color="isActive(link) ? 'CF197A' : '#AFBABF'" size="24">
            {{ link.icon }}
          </v-icon>
          <!-- 通知バッヂ -->
          <span v-if="link.is_newer" class="sp-notification-badge"></span>
          <span v-if="link.name == 'チャット' && unread_count > 0" class="notification-badge"></span>
        </div>
        <div class="mt-1">{{ link.name }}</div>
      </button>
    </div>
  </footer>
</template>

<script>
import config from "@/configs/common";
export default {
  props: { active: String },
  setup() {
    const { storeGetUnreadMessageLastReloadTime, storeSetUnreadMessageLastReloadTime,
      storeGetUnreadMessageCount, storeSetUnreadMessageCount } = require("@/helpers/store/storeAuthHelper")
    const { apiChatGetUnreadMessage } = require('@/helpers/common/common_api')
    const { debugLog } = require('@/helpers/common/datadog')
    const { getTimemngSpFooterLinks } = require('@/helpers/timemng/timemng_util')
    const { pageMove } = require('@/helpers/common/common_util')
    return {
      storeGetUnreadMessageLastReloadTime, storeSetUnreadMessageLastReloadTime,
      storeGetUnreadMessageCount, storeSetUnreadMessageCount,
      apiChatGetUnreadMessage,
      debugLog,
      getTimemngSpFooterLinks,
      pageMove,
    }
  },
  data: () => ({
    unread_count: 0,
  }),
  created() {
    this.checkAndInitChatRooms();
  },
  methods: {
    // チャット未読情報読み込み
    async checkAndInitChatRooms() {
      if (this.isShopShareMode()) return
      let unread_count = this.storeGetUnreadMessageCount();
      if (unread_count) this.unread_count = unread_count.unread
      const lastReloadString = this.storeGetUnreadMessageLastReloadTime()
      const lastReload = lastReloadString ? new Date(lastReloadString) : null;
      const now = new Date();
      if (!unread_count || !lastReload || Math.abs(now.getTime() - lastReload.getTime()) / 1000 > config.chat_message_reload_sec) {
        try {
          unread_count = await this.apiChatGetUnreadMessage()
          this.storeSetUnreadMessageLastReloadTime();
          this.storeSetUnreadMessageCount(unread_count);
          if (unread_count) this.unread_count = unread_count.unread
        } catch (err) {
          this.debugLog(err);
        }
      }
    },

    clickLink(link) {
      if (!link.active) {
        this.pageMove(link);
      }
    },
    isActive(link) {
      if (this.active) {
        return this.active == link.key;
      } else {
        return link.active;
      }
    },
  },
};
</script>

<style scoped>
.sp-notification-badge {
  right: 0;
}
</style>

