import api_common from "@/helpers/api_common.js";

export default {

    // 一覧取得
    getList(apiconf, staff_id, page) {
        const conditions = { page: page }
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, emergency, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    emergency: emergency,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "emergency/create",
            formdata: formdata,
        })
    },

    // 更新
    update(apiconf, staff_id, emergency) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/update",
            query: { staff_id: staff_id, emergency: emergency },
        })
    },

    // 適用店舗変更
    addTarget(apiconf, staff_id, emergency_id, target_id, with_chatmessage) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/add/target",
            query: { staff_id: staff_id, emergency_id: emergency_id, target_id: target_id, with_chatmessage: with_chatmessage, with_push: with_chatmessage },
        })
    },
    removeTarget(apiconf, staff_id, emergency_id, target_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/remove/target",
            query: { staff_id: staff_id, emergency_id: emergency_id, target_id: target_id },
        })
    },

    // タグ
    getTagList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/tags/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    createTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/create/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    addTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/add/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    removeTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/remove/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },

    // ファイル
    addAttachment(apiconf, staff_id, emergency, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    emergency: emergency,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "emergency/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachment(apiconf, staff_id, emergency) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/rm/attachment",
            query: { staff_id: staff_id, emergency: emergency },
        })
    },
    previewFile(apiconf, staff_id, emergency_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/emergency/file",
            query: {
                staff_id: staff_id,
                emergency_id: emergency_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    // ファイルキャプション
    saveAttachmentCaption(apiconf, staff_id, emergency) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/update/caption",
            query: { staff_id: staff_id, emergency: emergency },
        })
    },
}
