import api_common from "@/helpers/api_common.js";

export default {
    // 件数取得
    getStats(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/stats",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 一覧取得
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 一覧取得
    getList(apiconf, staff_id, dest_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/list",
            query: { staff_id: staff_id, dest_id: dest_id, page: page },
        })
    },

    // 通知バーに表示する通知メッセージ一覧を取得する
    getListForBar(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/bar/ep",
            query: { staff_id: staff_id },
        })
    },

    // 最新一覧
    getNewerList(apiconf, staff_id, dest_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/list",
            query: { staff_id: staff_id, dest_id: dest_id, page: page },
        })
    },

    // 追加
    create(apiconf, staff_id, notification) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/create",
            query: { staff_id: staff_id, notification: notification },
        })
    },

    // データファイルダウンロード
    downloadDataFile(apiconf, staff_id, service_type, file_id) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "download/datafile",
            query: {
                staff_id: staff_id,
                service_type: service_type,
                file_id: file_id,
            },
        })
    },
}