<template>
  <div>
    <!-- 通知区分：アナウンス通知 -->
    <div v-if="isDisplay && notifications_for_bar.notification_type === 1">
      <a
        @click="clickNoticeLink(notifications_for_bar.link_url)"
        class="sp-header-alert sp-header-alert-deletable"
      >
        <!-- クリックで遷移 -->
        <div class="sp-header-alert-body py-2 px-4">
          <div class="sp-header-alert-title">
            <span class="mr-2">{{ notifications_for_bar.title }}</span>
          </div>
          <div class="sp-header-alert-date mt-1">
            <span>{{ noticeDateStr( notifications_for_bar.published_at) }}</span>
          </div>
        </div>
        <div class="sp-header-alert-close">
          <!-- クリックで非表示 -->
          <button @click.stop="closeNoticeBar(notifications_for_bar.notice_key)">
            <v-icon size="small">mdi-close</v-icon>
          </button>
        </div>
      </a>
    </div>
    <!-- 通知区分：障害通知 -->
    <div v-if="notifications_for_bar.notification_type === 2">
      <a
        @click="clickNoticeLink(notifications_for_bar.link_url)"
        class="sp-header-alert sp-header-alert-undeletable"
      >
        <div class="mr-2">
          <v-icon size="small">mdi-alert</v-icon>
        </div>
        <!-- クリックで遷移 -->
        <div class="sp-header-alert-body">
          <div
            class="sp-header-alert-title"
          >
            <span class="mr-2">{{ notifications_for_bar.title }}</span>
          </div>
          <div class="sp-header-alert-date mt-1">
            <span class="sp-header-alert-last-modified">最終更新: </span>
            <span>{{ noticeDateTimeStr( notifications_for_bar.published_at) }}</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {  setup() {
    const { pageMove, openExternalWindow } = require("@/helpers/common/common_util");
    const { storeGetBeforeNoticeBarKey, storeSetBeforeNoticeBarKey } = require("@/helpers/store/storeSystemHelper")
    return {
      pageMove, openExternalWindow,
      storeGetBeforeNoticeBarKey, storeSetBeforeNoticeBarKey,
    }
  },

  props: {
    notifications_for_bar: {
      type: Object
    }
  },
  data() {
    return {
      is_show_announce: true
    }
  },
  computed: {
    isDisplay () {
      return this.is_show_announce && this.notifications_for_bar.notice_key !== this.storeGetBeforeNoticeBarKey()
    }
  },
  methods: {
    noticeDateStr (value) {
      return value.substring(0, value.indexOf("T"))
    },
    noticeDateTimeStr (value) {
      const date_time = value.replace("T", ' ')
      return date_time.substring(0, value.lastIndexOf(":"))
    },
    closeNoticeBar (notice_key) {
      this.is_show_announce = false
      this.storeSetBeforeNoticeBarKey(notice_key)
    },
    clickNoticeLink(link_url) {
      if (!link_url) {
        this.pageMove('/notification')
        return
      }
      this.openExternalWindow(link_url)
    }
  }
}
</script>

<style scoped>
/* 通知枠 */
.sp-header-alert {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-size: 12px;
}
.sp-header-alert-deletable {
  background-color: #FFE479;
  color: #222;
}
.sp-header-alert-undeletable {
  align-items: center;
  background-color: #D92A2A;
  color: #fff;
  padding: 8px 16px;
}
.sp-header-alert-undeletable a,
.sp-header-alert-undeletable i {
  color: #fff;
}

.sp-header-alert-body {
  width: 100%;
}
.sp-header-alert-title {
  display: flex;
  align-items: center;
}
.sp-header-alert-close {
  text-align: center;
  min-width: 46px;
  max-width: 46px;
}
.sp-header-alert-close button {
  display: block;
  width: 100%;
  height: 100%;
}
</style>