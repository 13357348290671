
import { getServiceType } from "@/helpers/environment";
import { isEnableRoutePolicy } from "@/helpers/common/role";
import { getRouter } from "@/helpers/common/common_util";

// 利用可能ルート一覧
export function getEnabledRoutes(route_type) {
  let routes = []
  RouteTypes[getServiceType()][route_type].forEach(r => {
    let route = getEnabledRoute(r)
    if (route) {
        routes.push(route)
    }
  });
  return routes
}
export function getEnabledRoute(route_name) {
  let route = _getRouterRoute(route_name)
  if (route) {
    if (isEnableRoutePolicy(route.policy)) {
      return route
    }
  }
}
export function isEnabledRoute(route_name) {
  return getEnabledRoute(route_name) ? true : false
}

// ルート取得
function _getRouterRoute(name) {
  const _route = getRouter().options.routes.find(v => v.name == name)
  if (_route) {
    let ret = { ...{}, ..._route.meta }
    ret.path = _route.path
    ret.linked_path = ret.path
    ret.name = _route.meta ? _route.meta.title : null
    return ret
  }
  const _parent = getRouter().options.routes.find(v => v.children && v.children.find(c => c.name == name))
  if (_parent) {
    const _children = _parent.children.find(v => v.name == name)
    let ret = { ...{}, ..._children.meta }
    ret.path = _parent.path + "/" + _children.path
    ret.linked_path = _parent.path + "/" + _children.path
    ret.name = _children.meta ? _children.meta.title : null
    return ret
  }
  return null
}

// ルートポリシー取得
export function getRouterPolicy(function_cd) {
  let policy = getRouter().options.routes.find(v => v.meta && v.meta.policy && v.meta.policy.function_cd == function_cd)
  if (policy) {
    return policy.meta.policy
  }
  const route = getRouter().options.routes.find(v => v.children && v.children.find(c => c.meta && c.meta.policy && c.meta.policy.function_cd == function_cd))
  if (route) {
    return route.children.find(c => c.meta && c.meta.policy && c.meta.policy.function_cd == function_cd).meta.policy
  }
  return null
}

// ヘルプ・インフォメーション
export function getHeaderHelpInfomationMenus() {
  return HelpInfomationRoutes
}

export default {
  getEnabledRoutes,
  getEnabledRoute,
  isEnabledRoute,
  getRouterPolicy,
  getHeaderHelpInfomationMenus,
}

const RouteTypes = {
  portal: {
    master: [
      "master-company",
      "master-brand",
      "master-area",
      "master-shop",
      "master-facility",
      "master-employee",
      "master-shop-employee",
      "master-position",
      "master-role",
    ],
    apps: [
      "setting-sso"
    ],
    invite: [
      "invite-invite"
    ],
    setting: [
      "auth_getuserinfo",
      "others-contactus",
    ],
    header_toolbar: [
      "others-notification",
      "chat",
    ],
    footer_toolbar_chat: [
      "chat",
    ],
    footer_toolbar_notification: [
      "others-notification",
    ],
  },

  timemanagement: {
    setting: [
      "auth_getuserinfo",
      "others-contactus",
    ],
    header_toolbar: [
      "others-notification",
      "chat",
    ],
    footer_toolbar_chat: [
      "chat",
    ],
  }
}

const HelpInfomationRoutes = [
  { text: "マニュアル", to: "/manual" },
  { text: "FAQ", to: "/faq" },
  { text: "通知（＝運営会社からのお知らせ）", to: "/system_info" },
  { text: "サービス情報・バージョンアップ情報", to: "/service_info" },
  { text: "お問い合わせ", to: "/contactus" },
]
