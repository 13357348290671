
import common_api from '@/helpers/common/common_api'
import storeAuth from "@/helpers/store/storeAuthHelper"
import { isUserHeadShop, isShopShareMode, isFranchiseOwnerHead } from '@/helpers/auth/auth_util'
import { arrayConcat, dataSort, getRoutePolicy, redirectNotFound, copyJson, isDisplayModeSP, isSystemApp } from '@/helpers/common/common_util'
import { getCompanySpecsTaskScheduleUiVer } from '@/helpers/auth/spec'

// ロール一覧取得
export function getRoleList() {
  return new Promise(function (resolve, reject) {
    common_api.apiGetRoleList().then(
      function (results) {
        let portal_roles = []
        let option_roles = []

        const _base_roles = getBaseRoles(results.data)
        results.data.filter(r => _base_roles.find(v => v.role.role_id == r.role.role_id) == undefined).forEach(r => {
          let role = r.role
          role.policies = r.policies
          option_roles.push(role)
        })

        _base_roles.forEach(r => {
          let role = r.role
          role.policies = r.policies
          role.option_roles = []
          role.role_cd.split(",").forEach(role_cd => {
            role.option_roles = arrayConcat(role.option_roles, option_roles.filter(o => o.role_cd == role_cd))
          })
          portal_roles.push(role)
        })
        let timemng_roles = option_roles.filter(r => ~r.role_id.indexOf('timemng_'))
        let multishops_roles = option_roles.filter(r => ~r.role_id.indexOf('multishops_'))
        dataSort(portal_roles, ["permission_level"], false);
        dataSort(portal_roles, ["updatable"], true);
        dataSort(timemng_roles, ["permission_level"], false);
        dataSort(timemng_roles, ["updatable"], true);
        dataSort(multishops_roles, ["permission_level"], false);
        dataSort(multishops_roles, ["updatable"], true);
        resolve({
          portal_roles: portal_roles,
          timemng_roles: timemng_roles,
          multishops_roles: multishops_roles,
        })
      }
      , function (err) {
        reject(err)
      })
  })
}

export function getUserCreatePortalRoleCd() {
  return UserCreatePortalRoleCd
}

// ベースロール取得
export function getBaseRoles(roles) {
  return roles.filter(r => {
    if (r.role) {
      return ~r.role.role_id.indexOf('portal_') || r.role.role_cd.includes(UserCreatePortalRoleCd)

    } else {
      return ~r.role_id.indexOf('portal_') || r.role_cd.includes(UserCreatePortalRoleCd)
    }
  })
}
export function getBaseRole(roles) {
  const base_roles = getBaseRoles(roles)
  if (base_roles.length > 0) {
    return base_roles[0]
  }
  return { role_id: null, role_cd: null }
}

// ベースロールからの取得メソッド
export function getRoleIdsByBaseRole(roles, target_role_id) {
  let results = []
  if (!roles || !target_role_id) return results
  const portal_role = roles.portal_roles.find(v => v.role_id == target_role_id)
  const timemng_roles = roles.timemng_roles.filter(v => portal_role.role_cd.includes(v.role_cd))
  const multishops_roles = roles.multishops_roles.filter(v => portal_role.role_cd.includes(v.role_cd))
  results.push(portal_role.role_id)
  timemng_roles.forEach(v => {
    results.push(v.role_id)
  })
  multishops_roles.forEach(v => {
    results.push(v.role_id)
  })
  return results
}
export function getRoleByBaseRole(roles, target_role_id) {
  let role = null
  if (!roles || !target_role_id) return role
  const target_role_ids = target_role_id.split(",")
  role = roles.portal_roles.find(v => target_role_ids.includes(v.role_id))
  if (role) return role
  role = roles.timemng_roles.find(v => target_role_ids.includes(v.role_id))
  if (role) return role
  role = roles.multishops_roles.find(v => target_role_ids.includes(v.role_id))
  if (role) return role
  return null
}
export function getRoleNameByBaseRole(roles, target_role_id) {
  const role = getRoleByBaseRole(roles, target_role_id)
  return role ? role.role_name : ""
}
export function getRoleNameByRoles(roles) {
  if (roles && roles.length > 0) {
    const base_role = getBaseRoles(roles)
    if (base_role && base_role.length > 0) {
      if (storeAuth.storeGetRoles()) {
        return getRoleNameByBaseRole(storeAuth.storeGetRoles(), base_role[0].role_id)
      }
    }
  }
}

// ポリシーファンクションコード判定
export function isEnabledPolicyByFunctionCd(function_cd) {
  const ret = storeAuth.storeGetSelectedPolicies().find(v => v.function_cd == function_cd)
  return ret != undefined;
}

// ポリシー判定
export function isEnabledPolicyCd(policy_cd, target_policies) {
  let selected_policies = []
  if (target_policies) {
    selected_policies = target_policies
  } else {
    selected_policies = storeAuth.storeGetSelectedPolicies()
  }
  if (Array.isArray(policy_cd)) {
    return policy_cd.find(cd => selected_policies.find(v => v.policy_cd == cd)) != undefined
  } else {
    return selected_policies.find(v => v.policy_cd == policy_cd) != undefined
  }
}

// ロール判定
export function isEnabledRoleCd(role_cds, target_role_cds) {
  let selected_role_cds = []
  if (target_role_cds) {
    selected_role_cds = target_role_cds
  } else {
    selected_role_cds = storeAuth.storeGetSelectedRoleCds()
  }
  if (Array.isArray(role_cds)) {
    const ret = role_cds.find(role_cd => selected_role_cds.includes(role_cd))
    return ret != undefined
  } else {
    return selected_role_cds.includes(role_cds)
  }
}

// 利用可能機能ポリシー判定
export function isEnabledFunctionPolicy(policy) {
  if (policy.function_cd) {
    let functions = storeAuth.storeGetSelectedPolicies().filter(v => v.function_cd == policy.function_cd)
    if (functions.length <= 0) {
      return false
    }
    for (let _crud of policy.crud) {
      if (!functions.find(v => v.crud.includes(_crud))) {
        return false
      }
    }
  }
  if (policy.is_head != undefined) {
    if (isUserHeadShop() != policy.is_head) {
      if (policy.forced_portal_admin == true) {
        if (!storeAuth.storeGetSelectedRoles().find(v => ~v.role_id.indexOf('portal_') && v.role_cd == 'admin')) return false
      } else {
        return false
      }
    }
  }
  if (policy.is_sp != undefined) {
    if (isDisplayModeSP() != policy.is_sp) {
      return false
    }
  }
  if (policy.is_app != undefined) {
    if (isSystemApp() != policy.is_app) {
      return false
    }
  }
  if (policy.fc_owner != undefined) {
    if (isFranchiseOwnerHead() != policy.fc_owner) {
      return false
    }
  }
  if (policy.task_schedule_ui_ver != undefined) {
    if (getCompanySpecsTaskScheduleUiVer() > policy.task_schedule_ui_ver) {
      return false
    }
  }
  if (policy.disabled_sharemode) {
    if (isShopShareMode()) return false
  }
  return true
}

// ルート利用可能判定
export function judgeRoutePolicy() {
  const route_policy = getRoutePolicy();
  if (route_policy) {
    if (isEnabledFunctionPolicy(route_policy)) {
      return route_policy
    }
    // nullの時はエラーNotFound
    return redirectNotFound()
  }
  return {}
}
export function isEnableRoutePolicy(route_policy, custom_policy = {}) {
  if (route_policy) {
    let policy = copyJson(route_policy)
    policy = Object.assign(policy, custom_policy)
    return isEnabledFunctionPolicy(policy)
  }
  return true
}
export function isEditableRoutePolicy() {
  // 先にjudgeRoutePolicy()は通っている前提で利用
  const route_policy = getRoutePolicy();
  if (route_policy) {
    let policy = copyJson(route_policy)
    policy.crud = ['c', 'u', 'd']
    return isEnabledFunctionPolicy(policy)
  }
  return false
}

// 選択中スタッフ権限判定
export function isEnabledStaffAdmin() {
  return isEnabledRoleCd(["admin"])
}
export function isEnabledStaffMultiShopManage() {
  return isEnabledPolicyCd(["multishops_manage_full", "multishops_manage_read"])
}
export function isEnabledStaffShopManage() {
  return isEnabledRoleCd(["manager"])
}

// 選択中企業権限判定
export function isEnabledCompanyAdmin() {
  return isEnabledRoleCd(["admin"], storeAuth.storeGetSelectedCompanyRoleCds())
}
export function isEnabledCompanyMultiShopManage() {
  if (isShopShareMode()) {
    return false
  } else {
    const staff = storeAuth.storeGetSelectedAssociate("staff");
    for (const staff_id of Object.keys(staff)) {
      for (const role of staff[staff_id].roles) {
        if (role.role_cd.split(",").find(v => v == "multishops_manage")) return true
      }
    }
    return false
  }
}
export function isEnabledCompanyShopManage() {
  return isEnabledRoleCd(["manager"], storeAuth.storeGetSelectedCompanyRoleCds())
}

export default {
  // ロール一覧取得
  getRoleList,
  getUserCreatePortalRoleCd,

  // ベースロール取得
  getBaseRoles,
  getBaseRole,

  // ベースロールからの取得メソッド
  getRoleIdsByBaseRole,
  getRoleByBaseRole,
  getRoleNameByBaseRole,
  getRoleNameByRoles,

  // ポリシーファンクションコード判定
  isEnabledPolicyByFunctionCd,

  // ポリシー判定
  isEnabledPolicyCd,

  // ロール判定
  isEnabledRoleCd,

  // 利用可能機能ポリシー判定
  isEnabledFunctionPolicy,

  // ルート利用可能判定
  judgeRoutePolicy,
  isEnableRoutePolicy,
  isEditableRoutePolicy,

  // 選択中スタッフ権限判定
  isEnabledStaffAdmin,
  isEnabledStaffMultiShopManage,
  isEnabledStaffShopManage,

  // 選択中企業権限判定
  isEnabledCompanyAdmin,
  isEnabledCompanyMultiShopManage,
  isEnabledCompanyShopManage,
}

const UserCreatePortalRoleCd = "userportal"


// const portal_roles = [
//   {
//     role_cd: "admin",
//     role_name: "管理者",
//   },
//   {
//     role_cd: "officer",
//     role_name: "役員",
//   },
//   {
//     role_cd: "manager",
//     role_name: "マネージャー",
//   },
//   {
//     role_cd: "staff",
//     role_name: "一般",
//   },
// ],

// const timemng_roles = [
//   {
//     role_cd: "admin",
//     role_name: "管理者",
//   },
//   {
//     role_cd: "manager",
//     role_name: "マネージャー",
//   },
//   {
//     role_cd: "staff",
//     role_name: "一般",
//   },
// ],

// // タイマネ⇒ポータル権限マッピング
// const timemng_to_portal_rolemap = {
//   admin: "admin",
//   officer: "manager",
//   manager: "manager",
//   staff: "staff",
// }