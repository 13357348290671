import api_common from "@/helpers/api_common.js";

export default {

    // 情報取得
    getOwnList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staffgroup/own/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staffgroup/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 作成
    create(apiconf, staff_id, staffgroup) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staffgroup/create",
            query: { staff_id: staff_id, staffgroup: staffgroup },
        })
    },

    // 更新
    update(apiconf, staff_id, staffgroup, roles, members, sso) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staffgroup/update",
            query: { staff_id: staff_id, staffgroup: staffgroup, roles: roles, members: members, sso: sso },
        })
    },

    // （従業員）一覧取得
    getEmployeeList(apiconf, staff_id, conditions) {
        // TODO:仮
        return api_common.callPostApi({
            conf: apiconf,
            path: "advance/staffgroup/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}