import api_common from "@/helpers/api_common.js";

export default {

    // 日付基準取得
    getToday(apiconf, staff_id, conditions) {
        const query = { staff_id: staff_id, conditions: conditions }
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/today",
            query: query,
        })
    },

    // 実績入力
    done(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "personal/task/done",
            formdata: formdata,
        })
    },

    // キャンセル
    cancel(apiconf, staff_id, task) {
        const query = { staff_id: staff_id, task: task }
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/cancel",
            query: query,
        })
    },

    // タスクコメント
    commentList(apiconf, staff_id, personal_task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/comment/list",
            query: { staff_id: staff_id, task: personal_task },
        })
    },
    upsertComment(apiconf, staff_id, personal_task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/comment/upsert",
            query: { staff_id: staff_id, task: personal_task },
        })
    },

    // 履歴
    logsList(apiconf, staff_id, personal_task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/logs/list",
            query: { staff_id: staff_id, task: personal_task },
        })
    },

    // プレビュー
    previewLogFile(apiconf, staff_id, personal_task_log_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/personal/task/result/file",
            query: {
                staff_id: staff_id,
                personal_task_log_id: personal_task_log_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // タスク実績一覧
    getStatusList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "personal/task/status/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}