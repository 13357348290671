import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        const conditions = { page: page }
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, pattern) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/create",
            query: { staff_id: staff_id, pattern: pattern },
        })
    },

    // 更新
    update(apiconf, staff_id, pattern) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/update",
            query: { staff_id: staff_id, pattern: pattern },
        })
    },

    // 曜日
    getApplyValues(apiconf, staff_id, pattern_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/apply/values/list",
            query: { staff_id: staff_id, pattern_id: pattern_id, page: page },
        })
    },
    addApplyValue(apiconf, staff_id, pattern_id, apply_value) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/add/apply",
            query: { staff_id: staff_id, pattern_id: pattern_id, apply_value: apply_value },
        })
    },
    removeApplyValue(apiconf, staff_id, pattern_id, apply_value) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/remove/apply",
            query: { staff_id: staff_id, pattern_id: pattern_id, apply_value: apply_value },
        })
    },

    // タスクグループ
    addTemplate(apiconf, staff_id, pattern_id, task_template_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/add/template",
            query: { staff_id: staff_id, pattern_id: pattern_id, task_templte_id: task_template_id },
        })
    },
    removeTemplate(apiconf, staff_id, pattern_id, task_template_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/remove/template",
            query: { staff_id: staff_id, pattern_id: pattern_id, task_templte_id: task_template_id },
        })
    },

    // 適用店舗
    addShop(apiconf, staff_id, pattern_id, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/add/shop",
            query: { staff_id: staff_id, pattern_id: pattern_id, shop_id: shop_id },
        })
    },
    removeShop(apiconf, staff_id, pattern_id, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/event/remove/shop",
            query: { staff_id: staff_id, pattern_id: pattern_id, shop_id: shop_id },
        })
    },

    // 【My店舗】一覧取得
    getListMyShop(apiconf, staff_id, shop_id, page, draft) {
        const conditions = { page: page, draft: draft }
        const query = { staff_id: staff_id, conditions: conditions }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/pattern/list",
            query: query,
        })
    },
    searchMyShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/pattern/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    // 【My店舗】作成
    createMyShop(apiconf, staff_id, pattern) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/pattern/create",
            query: { staff_id: staff_id, pattern: pattern },
        })
    },

    // 【My店舗】適用除外
    excludeTemplate(apiconf, staff_id, exclude) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/template",
            query: { staff_id: staff_id, exclude: exclude },
        })
    },
    excludeTask(apiconf, staff_id, exclude) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/task",
            query: { staff_id: staff_id, exclude: exclude },
        })
    },
    excludeTemplateList(apiconf, staff_id, shop_id) {
        const query = { staff_id: staff_id }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/template/list",
            query: query,
        })
    },
    excludeTaskList(apiconf, staff_id, shop_id) {
        const query = { staff_id: staff_id }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/task/list",
            query: query,
        })
    },
    excludeTemplateLogs(apiconf, staff_id, exid) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/template/logs",
            query: { staff_id: staff_id, exid: exid },
        })
    },
    excludeTaskLogs(apiconf, staff_id, exid) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/task/logs",
            query: { staff_id: staff_id, exid: exid },
        })
    },

    // 【My店舗】本部タスクカスタム
    customTaskHistory(apiconf, staff_id, approved_status, shop_id, pattern_ids) {
        const query = { staff_id: staff_id, approved_status: approved_status, shop_id: shop_id }
        if (pattern_ids) query.pattern_ids = pattern_ids
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/history",
            query: query,
        })
    },
    customTaskCreate(apiconf, staff_id, customs) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/create",
            query: { staff_id: staff_id, customs: customs },
        })
    },
    customTaskUpdate(apiconf, staff_id, customs) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/update",
            query: { staff_id: staff_id, customs: customs },
        })
    },
    customTaskApprove(apiconf, staff_id, approvals) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/approve",
            query: { staff_id: staff_id, approvals: approvals },
        })
    },
    customTaskRemand(apiconf, staff_id, remands) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/remand",
            query: { staff_id: staff_id, remands: remands },
        })
    },
    customReset(apiconf, staff_id, reset) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/custom/reset",
            query: { staff_id: staff_id, reset: reset },
        })
    },

    // 新デザイン形式（テンプレート無しバージョン）
    createNoTemplateVer(apiconf, staff_id, pattern, template) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "pattern/template/create",
            query: { staff_id: staff_id, pattern: pattern, template: template },
        })
    },
    createNoTemplateVerMyShop(apiconf, staff_id, pattern, template) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/pattern/template/create",
            query: { staff_id: staff_id, pattern: pattern, template: template },
        })
    },
}
