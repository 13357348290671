import api_common from "@/helpers/api_common.js";

export default {

    // 掲示板取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/message/list",
            query: { staff_id: staff_id, page: page, },
        })
    },


    // 掲示板取得
    getPenddingsList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/message/penddings",
            query: { staff_id: staff_id, page: page, },
        })
    },

    // 掲示板投稿
    post(apiconf, staff_id, post, tags, shared_files, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    post: post,
                    tags: tags,
                    shared_files: shared_files,
                }
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "forum/message/post",
            formdata: formdata,
        })
    },

    // 掲示板更新
    update(apiconf, staff_id, post) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/message/update",
            query: {
                staff_id: staff_id,
                post: post,
            },
        })
    },

    // タグ
    addTag(apiconf, staff_id, post_key, tag_text) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/event/add/tag",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                tag_text: tag_text,
            },
        })
    },
    removeTag(apiconf, staff_id, post_key, tag_text) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/event/rm/tag",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                tag_text: tag_text,
            },
        })
    },

    // 添付ファイル
    attachmentAdd(apiconf, staff_id, post_key, shared_files, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    post_key: post_key,
                    shared_files: shared_files,
                }
            }
        ]
        attached_files.forEach(attached => {
            formdata.push({ column: 'attached', value: attached });
        })
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "forum/event/add/attachment",
            formdata: formdata,
        })
    },
    attachmentDelete(apiconf, staff_id, post_key, file_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/event/rm/attachment",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                file_id: file_id,
            },
        })
    },
    attachmentEyecatchAdd(apiconf, staff_id, post_key, shared_files, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    post_key: post_key,
                    shared_files: shared_files,
                }
            }
        ]
        attached_files.forEach(attached => {
            formdata.push({ column: 'attached', value: attached });
        })
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "forum/event/add/eyecatch",
            formdata: formdata,
        })
    },

    // ファイルプレビュー
    previewFile(apiconf, staff_id, post_key, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/forum/file",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                file_id: file_id,
                original: original,
            },
        })
    },

    // リアクション
    addReaction(apiconf, staff_id, post_key, emoji) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/event/add/like",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                emoji: emoji,
            },
        })
    },
    removeReaction(apiconf, staff_id, post_key, emoji) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "forum/event/rm/like",
            query: {
                staff_id: staff_id,
                post_key: post_key,
                emoji: emoji,
            },
        })
    },
}