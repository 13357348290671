export default {

    api: {
        baseurl: "https://api.vue.eatery-portal.com/",
        version: "api/v0.1"
    },

    css: {
        prod: {
            sp: [
                "/css/sp/sp_style.css",
            ],
            pc: [
                "/css/pc/style.css",
                "/css/pc/timemanagement.css",
            ],
        },
        dev: {
            sp: [
                "/css/sp/sp_style.css",
            ],
            pc: [
                "/css/pc/style.css",
                "/css/pc/timemanagement.css",
            ],
        }
    },

    // アプリ用システム制御判定
    system_mode: {
        smartphone_app: "app",
        website: "web",
    },

    // Datadogログレベル
    datadog_level: {
        error: "warn",
        debug: "debug",
    },

    // モバイル基準値
    mobile_breakpoint: 760,

    // 暗号化キー
    crypto_key: "eatery-crypto!",

    // チャット再読み込み間隔
    chat_reload_msec: 30000,    //TODO:開発中は頻繁にアクセスしても邪魔なので間隔長めに

    // チャット新着メッセージ確認
    chat_message_reload_sec: 300,

    // トークンリフレッシュ間隔
    token_refresh_term_sec: 2 * 60 * 60,

    // 論理削除フラグ
    delete_flg: {
        false: 0,   // 削除しない
        true: 1,    // 削除する
    },

    // APIステータス
    api_status: {
        valid: 0,   // 有効
        invalid: 1, // 無効
    },

    // 画像圧縮
    image_compression: {
        maxSizeMB: 5,
        // maxWidthOrHeight: 640
    },

    // 添付ファイル
    attached_files : {
        limit_size: 10485760    // 10MB
    },

    // お知らせ
    notification: {
        download_checksheet_limit: 3,
    },

    // 多言語対応
    translate: {
        "en": {
            text: "英語",
            native_text: "English",
        },
        "ko": {
            text: "韓国語",
            native_text: "한국인",
        },
        "zh": {
            text: "中国語（簡）",
            native_text: "简体中文",
        },
        "zh-TW": {
            text: "中国語（繁）",
            native_text: "繁體中文",
        },
        "vi": {
            text: "ベトナム語",
            native_text: "Tiếng Việt",
        }
    },

    // ソースバージョン
    source_version: 20240130145045,
}