import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "position/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, position) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "position/create",
            query: { staff_id: staff_id, position: position },
        })
    },

    // 更新
    update(apiconf, staff_id, position) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "position/update",
            query: { staff_id: staff_id, position: position },
        })
    },
}