import config from '@/configs/timemanagement';

export default
    [
        // 本部用
        {
            path: config.basepath + '/report/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-headshop-report',
                    component: () => import('@/components/timemanagement/report/headshop/IndexHeadShopReport'),
                    meta: { title: 'レポート', policy: { is_head: true }, report_version: "v1" },
                },
            ]
        },
        {
            path: config.basepath + '/new-report/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-headshop-new-report',
                    component: () => import('@/components/timemanagement/report/headshop/IndexHeadShopReport'),
                    meta: { title: '新レポート', policy: { is_head: true }, report_version: "v2" },
                },
            ]
        },

        // エリアマネージャー用
        {
            path: config.basepath + '/areamanage/report',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-areamanage-report',
                    component: () => import('@/components/timemanagement/report/areamanage/IndexAreaManageReport'),
                    meta: { title: 'レポート', report_version: "v1" },
                },
            ]
        },
        {
            path: config.basepath + '/areamanage/new-report',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-areamanage-new-report',
                    component: () => import('@/components/timemanagement/report/areamanage/IndexAreaManageReport'),
                    meta: { title: '新レポート', report_version: "v2" },
                },
            ]
        },
    ]



