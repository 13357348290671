import api_common from "@/helpers/api_common.js";

export default {

    // 招待承認待ち一覧
    getPenddingList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staff/pendding/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 招待承認
    approve(apiconf, staff_id, approve_id, approval_result) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staff/approve",
            query: { staff_id: staff_id, approve_id: approve_id, approval_result: approval_result },
        })
    },

    // 更新
    update(apiconf, staff_id, staff, sso) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "staff/update",
            query: { staff_id: staff_id, staff: staff, sso: sso },
        })
    },
}