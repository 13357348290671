<template>
  <div v-if="!loading">
    <SpPage v-if="isDisplayModeSP()" :parents="params" />
    <PcPage v-else :parents="params" />
  </div>
</template>

<script>
import PcPage from "./PcHeadShopHome";
import SpPage from "./SpHeadShopHome";
export default {
  components: { PcPage, SpPage },
  setup() {
    const { getEnabledHeaderLinks } = require('@/helpers/service_util')
    const { isDisplayModeSP, setServiceBaseTitle } = require('@/helpers/common/common_util')
    const { getUserInfo } = require('@/helpers/auth/auth_util')
    const { isTimemngEnabledStaffType } = require('@/helpers/timemng/timemng_util')
    const { readNotificationsListForBar } = require('@/helpers/common/notifications')
    return {
      getEnabledHeaderLinks,
      isDisplayModeSP, setServiceBaseTitle,
      getUserInfo,
      isTimemngEnabledStaffType,
      readNotificationsListForBar,
    }
  },
  data: () => ({
    loading: true,
    params: {
      userinfo: {
        company_name: null,
        brand_name: null,
        shop_name: null,
        staff_name: null,
      },
      notifications_for_bar: null,
      role: null,
    },
  }),

  created() {
    const type = "headshop";
    this.params.userinfo = this.getUserInfo();
    if (this.isTimemngEnabledStaffType(this.params.userinfo, type)) {
      this.params.header_links = this.getEnabledHeaderLinks(
        "headshop_home"
      );
      this.setServiceBaseTitle();
      this.init();
    }
  },

  methods: {
    async init() {
      this.loading = false;
      this.setNotificationsListForBar();
    },
    async setNotificationsListForBar() {
      this.params.notifications_for_bar = await this.readNotificationsListForBar();
    },

    getIconColor(item) {
      return item.is_finished ? "teal lighten-1" : "grey lighten-1";
    },
  },
};
</script>