<template>
  <div>
    <CommonHeader :parents="parents"></CommonHeader>
    <AppAlert v-if="parents.notifications_for_bar" :notifications_for_bar="parents.notifications_for_bar" />
    <div class="d-flex">
      <LeftMenu :parents="parents" />
      <div class="pa-6 contents">
        <div class="grid-card-title">店舗の状況確認</div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  <span>
                    <v-icon color="#048CC3" size="24">mdi-chart-line</v-icon>
                  </span>
                  <span class="ml-2">分析・レポート</span>
                </div>
                <div class="mt-2">
                  レポート画面が新しくなりました！<br />
                  全体の状況も、個別店舗の詳細も、より確認しやすくなりました。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('/new-report')">レポート</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  <span>
                    <v-icon color="#048CC3" size="24">mdi-gauge</v-icon>
                  </span>
                  <span class="ml-2">ダッシュボード</span>
                </div>
                <div class="mt-2">
                  ブランド、エリアごとに実施状況を確認できます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('dashboard/task')">
                      日常業務
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <button @click="pageMove('dashboard/emergency')">
                      臨時タスク
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <button @click="pageMove('dashboard/checksheet')">
                      チェックシート
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <button @click="pageMove('dashboard/dailyreport')">
                      日報
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  <span>
                    <v-icon color="#048CC3" size="24">mdi-store-check-outline</v-icon>
                  </span>
                  <span class="ml-2">実施状況</span>
                </div>
                <div class="mt-2">
                  店舗、タスクごとに実施状況を確認できます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('confirmation/task')">
                      日常業務
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <button @click="pageMove('confirmation/emergency')">
                      臨時タスク
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <button @click="pageMove('confirmation/personal_task')">
                      個人タスク
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item" v-if="this.getCompanySpecsUseRanking().head">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  <span>
                    <v-icon color="#048CC3" size="24">mdi-crown</v-icon>
                  </span>
                  <span class="ml-2">ランキング</span>
                </div>
                <div class="mt-2">
                  ブランドごとに、店舗のタスク実施率・時間帯実施率の順位を確認できます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('ranking')">ランキング</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-card-title mt-10">設定・管理</div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">日常業務設定</div>
                <div class="mt-2">
                  すべての店舗に、業務を指示することができます。
                </div>
                <div class="d-flex flex-wrap" v-if="isNewTaskScheduleUi()">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/pattern')">
                      業務スケジュール
                    </button>
                  </div>
                </div>
                <div class="d-flex flex-wrap" v-else>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP1</div>
                    <button @click="pageMove('manage/task')">タスク</button>
                  </div>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP2</div>
                    <button @click="pageMove('manage/template')">
                      タスクグループ
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP3</div>
                    <button @click="pageMove('manage/pattern')">
                      業務スケジュール
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  チェックシート
                </div>
                <div class="mt-2">
                  日々のチェックシートを電子化することで、管理・提出が楽になります。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/checksheet')">
                      チェックシート
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  臨時タスク設定
                </div>
                <div class="mt-2">
                  いつもの業務に、緊急の指示を差し込むことができます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/emergency')">
                      臨時タスク
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  個人タスク設定
                </div>
                <div class="mt-2">
                  個別のユーザーに、業務を指示することができます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/personal_task')">
                      個人タスク
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card">
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">店舗一覧</div>
                <div class="mt-2">
                  V-Manageを利用中の店舗を確認できます。<br />
                  店舗ごとの業務内容・チェックシートを確認できます。
                </div>
                <div class="card-link mt-4">
                  <button @click="pageMove('/shops')">店舗一覧</button>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">利用設定</div>
                <div class="mt-2">V-Manageの利用設定はこちらから行います。</div>
                <div class="d-flex">
                  <div class="card-link mt-4">
                    <button @click="pageMove('/settings/company')">
                      利用設定
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/service/PcHeader";
import AppAlert from "@/components/timemanagement/PcAppAlert.vue";
import LeftMenu from "@/components/timemanagement/PcLeftMenu.vue";
export default {
  components: { CommonHeader, AppAlert, LeftMenu },
  props: ["parents"],
  setup() {
    const { getCompanySpecsUseRanking } = require('@/helpers/auth/spec')
    const { pageMove } = require('@/helpers/common/common_util')
    const { isHavingAdminManagerRoles, isNewTaskScheduleUi } = require('@/helpers/timemng/timemng_util')
    return {
      getCompanySpecsUseRanking,
      pageMove,
      isHavingAdminManagerRoles, isNewTaskScheduleUi,
    }
  },
};
</script>

<style scoped>
.v-application {
  background-color: unset !important;
}

.v-application ul {
  padding-left: 0 !important;
}

.v-application a {
  color: inherit;
}

.contents {
  width: calc(100% - 116px);
}

.grid-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
