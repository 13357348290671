import api_common from "@/helpers/api_common";

export default {
    // ディレクトリ取得
    getDirInfo(apiconf, staff_id, dir_id, with_pending) {
        const query = { staff_id: staff_id, with_pending: with_pending }
        if (dir_id) query.dir_id = dir_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/directory/info",
            query: query
        })
    },

    // ゴミ箱情報
    getTrashboxInfo(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/trushbox/info",
            query: { staff_id: staff_id }
        })
    },

    // ディレクトリ作成
    createDir(apiconf, staff_id, dir) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/directory/create",
            query: { staff_id: staff_id, dir: dir },
        })
    },

    // ディレクトリ更新
    updateDir(apiconf, staff_id, dir) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/directory/update",
            query: { staff_id: staff_id, dir: dir },
        })
    },

    // ファイル作成
    createFile(apiconf, staff_id, file, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    file: file,
                }
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "file/attachment/create",
            formdata: formdata,
        })
    },

    // ファイル更新
    updateFile(apiconf, staff_id, file) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/attachment/update",
            query: { staff_id: staff_id, file: file },
        })
    },

    // ファイルプレビュー
    preview(apiconf, staff_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/shared/file",
            query: {
                staff_id: staff_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // 分割アップロード
    uploadSplit(apiconf, staff_id, file, attached_file) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    file: file,
                }
            }
        ]
        formdata.push({ column: 'attached', value: attached_file });
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "file/split/upload",
            formdata: formdata,
        })
    },
    uploadUnify(apiconf, staff_id, file) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "file/unify/parts",
            query: { staff_id: staff_id, file: file },
        })
    },

    getPresignedUrl(apiconf, staff_id, file_id, specific_company_id, expiresin) {
        return api_common.callPostApi({
            throwallcheck: true,
            conf: apiconf,
            path: "file/presigned/url",
            query: {
                staff_id: staff_id,
                file_id: file_id,
                specific_shop_id: specific_company_id,
                expiresin: expiresin,
            },
        })
    }
}
