export default
    [
        {
            path: '/notification',
            component: () => import('@/components/common/IndexCommon'),
            children: [
                {
                    path: '',
                    name: 'others-notification',
                    component: () => import('@/components/common/notifications/NotificationsIndex'),
                    meta: { title: '通知', icon: "mdi-bell" },
                },
            ]
        },
    ]

