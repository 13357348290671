export default
    [{
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/components/error/notfound/IndexErrorNotFound'),
        meta: { title: 'ページが見つかりません' },
    },
    {
        path: '/noshop',
        name: 'noshop',
        component: () => import('@/components/error/noshop/IndexErrorNoShop'),
        meta: { title: '所属店舗がありません。' },
    },
    ]

