import config from '@/configs/timemanagement';
export default
    [
        {
            path: config.basepath + '/menus',
            name: 'timemanagement-menus',
            component: () => import('@/components/timemanagement/others/menus/IndexMenus'),
            meta: { title: 'メニュー' },
        },

        {
            path: config.basepath + '/help',
            name: 'timemanagement-help',
            component: () => import('@/components/timemanagement/others/help/IndexHelp'),
            meta: { title: 'ヘルプ' },
        },

        {
            path: config.basepath + '/help/startguide',
            name: 'timemanagement-startguide',
            component: () => import('@/components/timemanagement/others/help/IndexStart'),
            meta: { title: 'はじめての方' },
        },

        {
            path: config.basepath + '/help/manual',
            name: 'timemanagement-manual',
            component: () => import('@/components/timemanagement/others/help/IndexManual'),
            meta: { title: 'マニュアル' },
        },

        {
            path: config.basepath + '/help/faq',
            name: 'timemanagement-faq',
            component: () => import('@/components/timemanagement/others/help/IndexFaq'),
            meta: { title: 'FAQ' },
        },

        {
            path: config.basepath + '/settings/alert',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-settings-alert',
                    component: () => import('@/components/timemanagement/others/settings/alert/IndexSettingsAlert'),
                    meta: { title: '通知設定', icon: "mdi-cog", policy: { is_head: false, is_app: true } },
                },
            ]
        },
        {
            path: config.basepath + '/settings/company',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-settings-company',
                    component: () => import('@/components/timemanagement/others/settings/company/IndexSettingsCompany'),
                    meta: { title: '利用設定', policy: { function_cd: "task_schedule_ui_ver", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false } },
                },
            ]
        }
    ]


