export default {
    // MEMO：サービス別に書き換える
    service_type: "timemanagement",
    maintitle: "V-Manage",
    basepath: "",
    basecolor: {
        base: "#1976D2",   // blue-darken-2
        text: "#1976D2",
        background: "#ffffff",
        container_background: "#F8F5F5",
        isdark: false,
        input: "blue-darken-2",
    },
    menus: {
        headshop_home: {
            sort_number: 1,
            name: "ホーム",
            to: "/",
            is_head: true,
            icon: "mdi-home"
        },

        dashboard: {
            sort_number: 3,
            name: "ダッシュボード",
            is_multishops: true,
            is_sharemode: false,
            icon: "mdi-gauge",
            to: "/dashboard",
            sublinks: [
                {
                    name: " 日常業務 ",
                    to: "/dashboard/task",
                },
                {
                    name: "臨時タスク",
                    to: "/dashboard/emergency",
                },
                {
                    name: "チェックシート",
                    to: "/dashboard/checksheet",
                },
                {
                    name: "日報",
                    to: "/dashboard/dailyreport",
                },
            ]
        },
        todaytask: {
            sort_number: 4,
            name: "本日のタスク",
            to: "/todaytask",
            is_head: false,
            icon: "mdi-check-circle"
        },
        routine_dish: {
            sort_number: 5,
            name: "仕込み",
            to: "/routine/dish",
            is_head: false,
            icon: "mdi-bowl-mix"
        },
        routine_checksheet: {
            sort_number: 6,
            name: "チェックシート",
            is_head: false,
            icon: "mdi-playlist-check",
            sublinks: []
        },
        routine_dailyreport: {
            sort_number: 7,
            name: "日報・引継ぎ",
            is_head: false,
            to: '/routine/dailyreport',
            icon: "mdi-book-open"
        },
        other_shops: {
            sort_number: 8,
            name: "他店舗の状況",
            is_head: false,
            icon: "mdi-store-check-outline",
            sublinks: [
                {
                    name: "日報",
                    to: "/shops/dailyreport",
                }
            ]
        },
        calendar: {
            sort_number: 9,
            name: "カレンダー",
            is_head: false,
            to: `/myshop/calendar/${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
            icon: "mdi-calendar-outline"
        },
        personal_task: {
            sort_number: 11,
            name: "個人タスク",
            to: "/personal_task",
            icon: "mdi-account-check",
            is_sharemode: false,
        },
        ranking: {
            sort_number: 5,
            shop_sort_number: 13,
            name: "ランキング",
            to: "/ranking",
            icon: "mdi-crown",
        },
        shops: {
            sort_number: 7,
            name: "店舗一覧",
            to: "/shops",
            role_cds: ["admin"],
            is_head: true,
            icon: "mdi-store-settings"
        },
        task_confirmation: {
            sort_number: 4,
            name: "実施状況",
            role_cds: ["admin"],
            is_head: true,
            icon: "mdi-store-check-outline",
            sublinks: [
                {
                    name: "日常業務",
                    to: "/confirmation/task",
                },
                {
                    name: "臨時タスク",
                    to: "/confirmation/emergency",
                },
                {
                    name: "個人タスク",
                    to: "/confirmation/personal_task",
                },
            ]
        },
        timemanagement: {
            sort_number: 6,
            name: "業務管理",
            role_cds: ["admin", "manager"],
            is_head: true,
            icon: "mdi-wrench-cog",
            sublinks: [
                {
                    name: "日常業務",
                    thirdlinks: {
                        0: [
                            {
                                name: "タスク",
                                to: "/manage/task",
                            },
                            {
                                name: "タスクグループ",
                                to: "/manage/template",
                            },
                            {
                                name: "業務スケジュール",
                                to: "/manage/pattern",
                            },
                        ],
                        1: [
                            {
                                name: "業務スケジュール",
                                to: "/manage/pattern",
                            },
                            {
                                name: "タスク",
                                to: "/manage/task",
                            },
                        ]
                    }
                },
                {
                    name: "チェックシート",
                    to: "/manage/checksheet",
                },
                {
                    name: "臨時タスク",
                    to: "/manage/emergency",
                },
                {
                    name: "個人タスク",
                    to: "/manage/personal_task",
                },
            ]
        },

        myshop: {
            sort_number: 12,
            name: "業務管理",
            role_cds: ["admin", "manager"],
            is_head: false,
            is_sharemode: false,
            icon: "mdi-wrench-cog",
            sublinks: [
                {
                    name: "日常業務",
                    thirdlinks: {
                        0: [
                            {
                                name: "タスク",
                                to: "/myshop/manage/task",
                            },
                            {
                                name: "タスクグループ",
                                to: "/myshop/manage/template",
                            },
                            {
                                name: "業務スケジュール",
                                to: "/myshop/manage/pattern",
                            },
                        ],
                        1: [
                            {
                                name: "業務スケジュール",
                                to: "/myshop/manage/pattern",
                            },
                            {
                                name: "タスク",
                                to: "/myshop/manage/task",
                            },
                        ]
                    }
                },
                {
                    name: "チェックシート",
                    to: "/myshop/manage/checksheet",
                },
                {
                    name: "臨時タスク",
                    to: "/myshop/manage/emergency",
                },
            ]
        },
        analysis: {
            sort_number: 2,
            name: "分析",
            to: "/new-report",
            role_cds: ["admin"],
            is_head: true,
            icon: "mdi-chart-line"
        },
        settings_company: {
            sort_number: 98,
            name: "利用設定",
            to: "/settings/company",
            role_cds: ["admin"],
            is_head: true,
            icon: "mdi-cog"
        },
        settings_alert: {
            sort_number: 99,
            name: "通知設定",
            to: "/settings/alert",
            is_head: false,
            is_app: true,
            icon: "mdi-cog"
        },
    }
}