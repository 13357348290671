import api_common from "@/helpers/api_common.js";

export default {
    // 翻訳
    textTranslate(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "translate/text",
            query: { staff_id: staff_id, translate: translate },
        })
    },

    // 翻訳設定
    addTranslationTask(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/add/translation",
            query: { staff_id: staff_id, translate: translate },
        })
    },
    addTranslationTaskSub(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/add/translation",
            query: { staff_id: staff_id, translate: translate },
        })
    },
    addTranslationEmergency(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/event/add/translation",
            query: { staff_id: staff_id, translate: translate },
        })
    },

    // 翻訳（チェックシート）
    addTranslationChecksheet(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/add/translation",
            query: { staff_id: staff_id, translate: translate },
        })
    },
    addTranslationChecksheetDetail(apiconf, staff_id, translate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/add/detail/translation",
            query: { staff_id: staff_id, translate: translate },
        })
    },
}
