import api_common from "@/helpers/api_common.js";

export default {

    // フランチャイザー契約一覧
    getFranchiserRights(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "franchise/rights/owns/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // フランチャイジー契約一覧
    getFranchiseeRights(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "franchise/rights/approved/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // フランチャイズ契約作成
    // createRights(apiconf, staff_id, rights) {
    //     return api_common.callPostApi({
    //         conf: apiconf,
    //         path: "franchise/rights/create",
    //         query: { staff_id: staff_id, rights: rights },
    //     })
    // },

    // フランチャイズ契約内容更新
    updateRights(apiconf, staff_id, rights) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "franchise/rights/update",
            query: { staff_id: staff_id, rights: rights },
        })
    },

    // 店舗一覧
    shopList(apiconf, staff_id, conditions, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "advance/shop/list",
            query: { staff_id: staff_id, conditions: conditions, page: page },
        })
    },

    // フランチャイズ店舗一覧
    shopFranchisingList(apiconf, staff_id, conditions, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "advance/franchising/shop/list",
            query: { staff_id: staff_id, conditions: conditions, page: page },
        })
    },
}