export default
    [
        {
            path: '/test',
            component: () => import('@/components/test/Index'),
            children: [
                {
                    path: 'displaymode',
                    name: 'tests-displaymode',
                    component: () => import('@/components/test/SetDisplayMode'),
                    meta: { title: '【テスト】表示モード設定' },
                }
            ]
        },
    ]

