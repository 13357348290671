
import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "policy/list",
            query: { staff_id: staff_id, page: page },
        })
    },
}