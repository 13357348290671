import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, tag_type, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tags/list",
            query: { staff_id: staff_id, tag_type: tag_type, page: page },
        })
    },

    // 新規作成
    create(apiconf, staff_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tag/event/add/tags",
            query: { staff_id: staff_id, tags: tags },
        })
    },
}
