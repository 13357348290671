<template>
  <header class="sp-toolbar">
    <div class="tool-account">
      <div class="tool-account-avatar">
        <v-avatar size="36" style="margin: 4px 0 6px" @click="toolbarMenu = !toolbarMenu">
          <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
          <v-icon v-else color="blue">mdi-account</v-icon>
        </v-avatar>
        <div class="sp-toolbar-menu" v-if="toolbarMenu">
          <div class="sp-toolbar-menu-inner">
            <div class="sp-toolbar-switch" v-if="isEnabledShopShareMode()">
              <div class="sp-toolbar-switch-title">
                <v-icon color="#103087">mdi-account-multiple</v-icon>
                店舗共有モード
              </div>
              <div class="sp-toolbar-switch-switch">
                <label for="switch1" class="d-flex align-center pa-4">
                  <input type="checkbox" class="sp-switch-checkbox" id="switch1" v-model="share_mode"
                    @change="changeShopShareMode(this, share_mode)" />
                  <div class="sp-switch-text sp-switch-text-off mr-2">OFF</div>
                  <label class="sp-switch-label" for="switch1"></label>
                  <div class="sp-switch-text sp-switch-text-on ml-2">ON</div>
                </label>
              </div>
            </div>
            <div class="sp-toolbar-account">
              <div class="sp-toolbar-account-avatar">
                <v-avatar size="40" @click="toolbarMenu = !toolbarMenu">
                  <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" cover />
                  <v-icon v-else color="blue">mdi-account</v-icon>
                </v-avatar>
              </div>
              <div class="sp-toolbar-account-name">
                {{ getUserName() }}
              </div>
            </div>
            <div class="sp-toolbar-belongs">
              <div class="sp-toolbar-company">
                {{ getUserCompanyFullName() }}
              </div>
              <div class="sp-toolbar-brand">
                {{ getUserBrandName() }}
              </div>
              <div class="sp-toolbar-shop">
                {{ getUserShopName() }}
              </div>
              <div class="sp-toolbar-role">
                {{ getUserRoleName() }}
              </div>
            </div>
            <div class="sp-toolbar-change">
              <button @click="viewChangeShop()">
                <i class="mdi mdi-cached"></i>
                <span>店舗切り替え</span>
              </button>
            </div>
            <div class="sp-toolbar-devider"></div>
            <div class="sp-toolbar-latest">
              <button @click="pageMove('/auth/getuserinfo')">
                最新情報を取得
              </button>
            </div>
            <div class="sp-toolbar-auth">
              <button @click="pageMove('/auth/signout')">サインアウト</button>
            </div>
            <div class="sp-toolbar-close">
              <button @click="toolbarMenu = false">
                <i class="mdi mdi-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tool-name">
      <img :src="getLogoVManage()" width="110" />
      <button class="sp-tool-switch-btn" @click="toolSwitchMenu = !toolSwitchMenu">
        <i class="mdi mdi-chevron-down"></i>
      </button>
      <div v-if="toolSwitchMenu" class="sp-swich-drawer">
        <div class="sp-swich-item" @click="redirectPortal(this)">
          <a>
            <img :src="getLogoFPortal()" width="110" />
          </a>
        </div>
        <div class="sp-swich-item">
          <img :src="getLogoVManage()" width="110" />
        </div>
      </div>
    </div>
    <div class="tool-change">
      <button>
        <i class="mdi mdi-cached"></i>
      </button>
    </div>
    <ChangeShopModal v-if="change_shop_modal" :close="changeShop" />
    <ExecutingOverlay ref="execute" />
  </header>
</template>

<script>
import ChangeShopModal from "@/components/common/change_shop/SpChangeShopModal";
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
export default {
  props: ["parents"],
  components: { ChangeShopModal, ExecutingOverlay },
  setup() {
    const { getUserName, getUserCompanyFullName, getUserBrandName, getUserRoleName,
      getUserShopName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode
    } = require('@/helpers/auth/auth_util')
    const { changeShopShareMode, redirectPortal } = require('@/helpers/timemng/timemng_util')
    const { pageMove, getLogoFPortal, openExternalWindow, getLogoVManage } = require('@/helpers/common/common_util')
    return {
      getUserName, getUserCompanyFullName, getUserBrandName, getUserRoleName,
      getUserShopName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode,
      changeShopShareMode, redirectPortal,
      pageMove, getLogoFPortal, openExternalWindow, getLogoVManage,
    }
  },
  data: () => ({
    url_avatar: true,

    change_service: false,
    change_shop_modal: false,
    confirm_modal: false,

    toolbarMenu: false,
    toolSwitchMenu: false,
    share_mode: false,
  }),

  created() {
    this.share_mode = this.isShopShareMode();
  },

  methods: {
    viewCompanyOrShopName() {
      return this.parents.userinfo.is_head_shop
        ? this.parents.userinfo.company_name
        : this.parents.userinfo.shop_name;
    },

    checkHeaderLink(link) {
      if (link.external) {
        this.openExternalWindow(link.external);
      } else {
        this.pageMove(link.to);
      }
    },

    viewChangeShop() {
      this.change_shop_modal = true;
    },
    changeShop() {
      this.change_shop_modal = false;
    },

    // 遷移確認
    confirmMove(event) {
      if (this.parents.is_move_confirm) {
        this.save_event = event;
        this.confirm_modal = true;
        return true;
      }
      return false;
    },
    // execMove(is) {
    //   if (is) {
    //   }
    //   this.confirm_modal = false;
    // },
  },
};
</script>

