import api_common from "@/helpers/api_common.js";

export default {

    // 一覧取得
    getList(apiconf, staff_id, page, template_exist) {
        const conditions = { page: page, template_exist: template_exist }
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 検索
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, template) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/create",
            query: { staff_id: staff_id, template: template },
        })
    },

    // 更新
    update(apiconf, staff_id, template) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/update",
            query: { staff_id: staff_id, template: template },
        })
    },

    // タスク
    addTask(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/event/add/task",
            query: { staff_id: staff_id, task: task },
        })
    },
    removeTask(apiconf, staff_id, task_template_id, task_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "template/event/remove/task",
            query: { staff_id: staff_id, task_template_id: task_template_id, task_id: task_id },
        })
    },

    // （My店舗）一覧取得
    getListMyShop(apiconf, staff_id, shop_id, page, template_exist) {
        const conditions = { page: page, template_exist: template_exist }
        const query = { staff_id: staff_id, conditions: conditions }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/template/list",
            query: query,
        })
    },

    // （My店舗）検索
    searchMyShop(apiconf, staff_id, shop_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/template/list",
            query: {staff_id: staff_id, shop_id: shop_id, conditions: conditions}
        })
    },

    // （My店舗）新規作成
    createMyShop(apiconf, staff_id, template) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/template/create",
            query: { staff_id: staff_id, template: template },
        })
    },
}