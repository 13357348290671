import { defineStore } from 'pinia';

export const useStoreSystem = defineStore('eatery-system', {
  persist: true,
  state: () => ({
    display_mode: null,
    // 通知バーの表示制御で使用（通知を非表示にした際に通知IDを保存する）
    before_notice_bar_key: null,

    // 本日のタスク表示制御
    todaytask_display_setting: {
      font_size: "font-size-medium",
      content_value: {
        has_task_position: true,
        has_task_detail: false,
        has_task_file: false,
        has_task_checksheet_connect: false
      },
      translate_lang: "",
    },
    // 個人タスク表示制御
    personal_task_display_setting: {
      font_size: "font-size-medium",
      content_value: {
        has_task_detail: false,
        has_task_file: false,
      },
    },
  }),
});